angular.module('angus.controllers').controller('alertResultsWidgetSettingsController', [
    '$rootScope', '$scope', 'alertsFrameworkService', 'promiseMonitor',
    function($rootScope, $scope, alertsFrameworkService, promiseMonitor) {

        const subscriberKey = $rootScope.user.subscriberAbosKey;
        
        $scope.promiseMonitor = new promiseMonitor();

        $scope.alertChanged = () => {
            const alertKey = $scope.widget.instance.settings.alertKey
            const alert = $scope.alerts.find(alert => alert.alertKey === alertKey);

            $scope.widget.instance.settings.title = alert.alertName;
        }

        const promise = alertsFrameworkService.getAlerts(subscriberKey)
            .then(alerts => {
                $scope.alerts = alerts;
            });

        $scope.promiseMonitor.monitor(promise)
    }
])