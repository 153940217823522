'use strict';

angular.module('angus.controllers').controller('serviceCallsCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'dateToString', 'productsService', '$q', 'hierarchyService', 'actionViewTemplates',
    'constantsService', '_', 'moment', 'datePickerService', 'lastInvoiceDateService', 'dateCalculator', 'restrictedAccessService', 'fiscalYearService',
    function($scope, $rootScope, $http, modalService, dateToString, productsService, $q, hierarchyService, actionViewTemplates,
             constantsService, _, moment, datePickerService, lastInvoiceDateService, dateCalculator, restrictedAccessService, fiscalYearService) {

        $scope.periodIndicators = constantsService.servicePeriodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.servicePeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        var divisionSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        restrictedAccessService
            .getAccess()
            .then(function(access){
                $scope.restrictedAccess = access.restrictedAccess;
            });

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastInvoiceDateService.getLastInvoiceDate(divisionSetting,undefined, 1 )
                .then(function(lastPostingDate) {

                    if(!$scope.dates){
                        $scope.dates    = {};
                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastPostingDate || moment.utc(), $scope.periodIndicator.key);

                        if($scope.periodIndicator.key === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastPostingDate = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    return queryServiceCallsSummary();
                })
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.calls = response.data;
                    $scope.widget.setDefaultActionModel($scope.dates);
                });

            return $scope.widget.promises.monitor(promise);
        }

        function reloadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = queryServiceCallsSummary()
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.calls = response.data;
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryServiceCallsSummary() {
            return $http({
                url: ('api/subscribers/{0}/service/calls/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    d  : divisionSetting,
                    pi : $scope.periodIndicator.key,
                    sr : $scope.widget.instance.settings.serviceRevenueCategory.value,
                    cr : $scope.widget.instance.settings.callReasons ? $scope.widget.instance.settings.callReasons.value : [],
                    t  : $scope.widget.instance.settings.techs.value,
                    sd : $scope.dates.periodStartDate.format(),
                    ed : $scope.dates.lastPostingDate.format(),
                    usePostingDate: $scope.widget.instance.settings.usePostingDate.value,
                    serviceCallWorkPerformedKeys: $scope.widget.instance.settings.serviceCallWorkPerformedKeys.value,
                    serviceCallFunctionKeys: $scope.widget.instance.settings.serviceCallFunctionKeys.value,
                }
            });
        }

        $scope.$on('widgetSettingsChanged', function() {
            divisionSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

            $scope.periodIndicator = _.find(constantsService.servicePeriodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            delete $scope.dates;
            loadWidget();
        });

        $scope.openDatePicker = function() {
            return lastInvoiceDateService.getLastInvoiceDate(divisionSetting,undefined, 1 )
                .then(function(lastPostingDate) {
                    return datePickerService.openDatePickerModal(

                        constantsService.servicePeriodIndicators.lastPostingDate.is($scope.widget.instance.settings.periodIndicatorId.value) ?
                            null : $scope.dates.periodStartDate,
                        $scope.dates.lastPostingDate,
                        lastPostingDate);
                })
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastPostingDate = resultDates[1];

                        reloadWidget();

                        $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.servicePeriodIndicators.customDate ||
                            $scope.widget.instance.settings.periodIndicatorId.value == constantsService.servicePeriodIndicators.lastPostingDate) ?
                            constantsService.servicePeriodIndicators.customDate : constantsService.servicePeriodIndicators.customDateRange;
                    }
                });
        };

        function setDateTooltipText() {
            if (!constantsService.servicePeriodIndicators.lastPostingDate.is($scope.widget.instance.settings.periodIndicatorId.value) && $scope.dates.periodStartDate) {
                $scope.dateTooltipText = $scope.dates.periodStartDate.format('L') + ' - ' + $scope.dates.lastPostingDate.format('L');
            } else {
                $scope.dateTooltipText = $scope.dates.lastPostingDate.format('L');
            }
        }
    }
]);