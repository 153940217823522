angular.module('angus.controllers').controller( 'subRegCtrl', [
	'$scope', '$rootScope', '_', 'actionViews', 'constantsService', 'subscriberService',
	function( $scope, $rootScope, _, actionViews, constantsService, subscriberService) {
		'use strict';

		$scope.searchTerm = '';	  
		$scope.sortBy = 'subscriberName';
		$scope.ascending = true;  

		$scope.clearSearch = function() {
			$scope.searchTerm = '';
			return $scope.getSubscribers();
		};

		$scope.subscriberSelected = function( subscriberId, itemName, registrationStatusKey, isPaygo, subscriberKey ) {
			$rootScope.impersonateAbosKey = subscriberKey;
			$scope.widget.openDetailView( actionViews.getActionView( $scope.widget.code ), {
				subscriberId: subscriberId,
				itemName: itemName,
				registrationStatusKey: registrationStatusKey,
				subscriberIsPaygo : isPaygo
			} );
		};


		$scope.getSubscribers= function() {
			const isAdept =  $scope.widget.instance.settings.isAdept || false;
			const isBrite = $scope.widget.instance.settings.isBrite || false;
			const isAms = $scope.widget.instance.settings.isAms || false;
			const includeInternal = false;
			const registrationStatusKeys = $scope.widget.instance.settings.registrationStatusKeys;
			const backOfficeSystemKeys = $scope.widget.instance.settings.backOfficeSystemIds;
			const searchTerm = $scope.searchTerm;

			return subscriberService.getAll(isAdept, isBrite, isAms, includeInternal, registrationStatusKeys, backOfficeSystemKeys, searchTerm)
				.then(function (data) {
					var subscribers = data;

					subscribers.forEach(function(subscriber){
						var currentDate = new Date();
						var insertDate = new Date(subscriber.insertDate);

						var elapsed = currentDate - insertDate;

						var loyaltyMonths = Math.floor(elapsed / 2629743000);
						var loyaltyYears = Math.floor(loyaltyMonths / 12);

						subscriber.loyaltyYears = loyaltyYears.toString() + 'y ' + (loyaltyMonths - (loyaltyYears * 12)).toString().padStart(2, '0') + 'm';
						
						if(subscriber.registrationStatusKey == constantsService.registrationStatus.live) {
							subscriber.displayStatus = 'Live';
						}
						else if(subscriber.registrationStatusKey == constantsService.registrationStatus.connectionSetup) {
							subscriber.displayStatus = 'Connection Setup';
						}
						else {
							subscriber.displayStatus = 'Deleted';
						}
					});

					$scope.subscribers = sortSubscribers(subscribers, $scope.sortBy, $scope.ascending);

					/* Inside the promise reset the column visibility. That way the columns don't flash on or off when the settings change. */
					setColumnVisibility()

					$scope.widget.contentLoaded = true;
				});
		};

		$scope.sort=function(sortBy, direction) {
			if ($scope.sortBy == sortBy) {
				 $scope.ascending = !$scope.ascending;
			} else {
				$scope.sortBy = sortBy;

				if (direction === 'descending')
				{
					$scope.ascending = false;
				}
				else {
					$scope.ascending = true;
				}
			}

			$scope.subscribers = sortSubscribers($scope.subscribers, $scope.sortBy, $scope.ascending);
		}

		function sortSubscribers (subscribers, sortBy, sortAscending) {
			const subscriberNameSortBy = function(subscriber) {
				return subscriber.subscriberName.toUpperCase();
			}

			const backOfficeSystemsSortBy = function(subscriber) {
				return subscriber.backOfficeSystemsCsv.toUpperCase();
			}

			if (sortBy === 'subscriberName') {
				sortBy = subscriberNameSortBy;
			}
			else if (sortBy === "backOfficeSystemsCsv") {
				sortBy = backOfficeSystemsSortBy;
			}

			return _.orderBy(subscribers, [sortBy, subscriberNameSortBy], [sortAscending ? 'asc' : 'desc', 'asc']);
		}

		function setColumnVisibility(){
			const registrationStatusKeys = $scope.widget.instance.settings.registrationStatusKeys;

			/* Determine if we should show the Live column. Show it if at least 2 registration statues are selected and one of them is the Live status */
			$scope.isLiveVisible = false;
			
			if(!registrationStatusKeys || registrationStatusKeys.length == 0){
				/* No statuses were selected */
				$scope.isLiveVisible = true;
			}
			else if (registrationStatusKeys.length > 1 && registrationStatusKeys.includes(constantsService.registrationStatus.live.toString())) {
				/* More than one status was selected and one of those statues is Live */
				$scope.isLiveVisible = true;
			}


			/* Only show the registration status column if Deleted is selected */	
			$scope.isStatusVisible = false;
			
			if(!registrationStatusKeys || registrationStatusKeys.length == 0){
				/* No statuses were selected */
				$scope.isStatusVisible = true;
			}
			else if (registrationStatusKeys.includes(constantsService.registrationStatus.deleted.toString())){
				/* No statuses selected, so all will be included. Show the Live column */
				$scope.isStatusVisible = true;
			}
		}

		
		function init() {		
			return $scope.widget.promises.monitor($scope.getSubscribers());			
		}

        $scope.widget.setDefaultInitialization(init);

		$scope.search = function() {
			$scope.getSubscribers();
		};

		$scope.$on('widgetSettingsChanged', function() {
           init();
        });
	} 
] );