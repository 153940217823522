angular.module('angus.controllers').controller('marginToBudgetSubRegSettingsCtrl', [
    '$scope', '$rootScope', '$q', 'constantsService','promiseMonitor', '$http', 'budgetsService', '_',
    function($scope, $rootScope, $q, constantsService, promiseMonitor, $http, budgetsService, _) {
        'use strict';

        $scope.promiseMonitor = new promiseMonitor();

        $scope.periodIndicators   = constantsService.budgetPeriodIndicators.enums;

        var budgets = new budgetsService($scope.subscriberId);

        function selectedCategory(categories, categoryIds){
            _.forEach(categories, function(category){
                category.selected = _.some(categoryIds, function(categoryId){ return category.id == categoryId }) ;
            });
        }

        var promise = $q
            .all([
                budgets
                    .getProducts()
                    .then(function(productCategories){
                        selectedCategory(productCategories, $scope.settings.productCategoryIds ? $scope.settings.productCategoryIds.value : []);
                        $scope.productCategories = productCategories;
                    }),
                budgets
                    .getTradeClasses()
                    .then(function(tradeClassCategories){
                        selectedCategory(tradeClassCategories, $scope.settings.tradeClassCategoryIds ? $scope.settings.tradeClassCategoryIds.value : []);
                        $scope.tradeClassCategories = tradeClassCategories;
                    }),
                budgets
                    .getPricePlans()
                    .then(function(pricePlanCategories){
                        selectedCategory(pricePlanCategories, $scope.settings.pricePlanCategoryIds ? $scope.settings.pricePlanCategoryIds.value : []);
                        $scope.pricePlanCategories = pricePlanCategories;
                    })
            ]);

        $scope.promiseMonitor.monitor(promise);
    }
]);
