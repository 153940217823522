angular.module('angus.controllers').controller('tmsMonitorAdminCtrl', [
    '$scope', '$rootScope','Paging', 'fluentRest', 'Throttler', 'actionViews','promiseMonitor', '_', 'subscriberService',
    function($scope, $rootScope, Paging, fluentRest, Throttler, actionViews, PromiseMonitor, _, subscriberService) {
        'use strict';

        $scope.load = true;
        $scope.selectedSubscriber = null;
        $scope.subscribersList = null; 
        $scope.paging = new Paging(500);
        $scope.clearSearch = function() {
            delete $scope.searchTerm;
            setModel();
        };

        var promise = subscriberService.getAmsSubscribers()
            .then(subscribers => {
                $scope.subscribersList = subscribers;
                $scope.subscribersList.unshift({abosKey : -2, name : '-NO SUBSCRIBER-'});
                $scope.subscribersList.unshift({abosKey : -1, name : '-ALL SUBSCRIBERS-'});
            });

        $scope.monitorsMonitor = new PromiseMonitor(promise);

        $scope.getMonitors = function(pageNumber) {
            var params = {
                searchTerm : $scope.searchTerm,
                sortBy     : $scope.sortBy,
                ascending  : $scope.ascending,
                subscriberABOSKey: $scope.selectedSubscriber ? $scope.selectedSubscriber.abosKey : null
            };

            var url = fluentRest.api().monitors().toString();

            return $scope.paging.getPage(url, pageNumber, params)
                .then(function(monitors) {
                    $scope.monitors = monitors.items;
                })
                .catch(function(){
                    $scope.monitors = [];
                });
        };

        function setModel(){
            $scope.widget.setDefaultActionModel({
                subscriber  : $scope.selectedSubscriber,
                searchTerm  : $scope.searchTerm
            });
        }
        $scope.searchTermChange = function(term){
            $scope.searchThrottler
                .throttle()
                .then(function(takeAction) {
                    if(takeAction){
                        $scope.searchTerm = term;
                        setModel();
                        $scope.getMonitors();
                    }
                });
        };

        $scope.subscriberChange = function(subscriber){
            $scope.selectedSubscriber = subscriber;
            setModel();
            $scope.getMonitors();
        }

        $scope.sort = function(sortBy) {
            if ($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else {
                $scope.sortBy = sortBy;
                $scope.ascending = true;
            }
            $scope.getMonitors();
        };

        $scope.monitorSelected = function(monitor) {
            $scope.widget.openDetailView(actionViews.getActionView($scope.widget.code), {
                monitor: monitor
            });
        };

        function init() {

            $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';

            $scope.paging = new Paging($scope.pageSize || 10);
            $scope.sortBy = 'serialNumber';
            $scope.ascending = true;

            $scope.searchThrottler = new Throttler(250);

            var promise =  Promise.resolve();

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(init);


        $scope.$on('widgetSettingsChanged', function() {
            setModel();
            $scope.getMonitors();
        });
    }
]);
