angular.module('angus.controllers').controller('serviceCallsSettingsCtrl', [
    '$scope', '$rootScope', '$http', '_', 'constantsService', 'hierarchyService', 'serviceCallsService',
    function($scope, $rootScope, $http, _, constantsService, hierarchyService, serviceCallsService) {
        'use strict';

        const subscriberKey = $rootScope.user.subscriberAbosKey;

        $scope.widget.instance.settings.callReasons = $scope.widget.instance.settings.callReasons || {};
        $scope.widget.instance.settings.techs = $scope.widget.instance.settings.techs || {};

        $scope.widget.instance.settings.serviceCallWorkPerformedKeys = $scope.widget.instance.settings.serviceCallWorkPerformedKeys || {};
        $scope.widget.instance.settings.serviceCallFunctionKeys = $scope.widget.instance.settings.serviceCallFunctionKeys || {};

        $http.get(('api/subscribers/{0}/service/techs').format($rootScope.user.subscriberId))
            .then(function(list) {
                $scope.techs = list.data;

                _.forEach($scope.widget.instance.settings.techs.value, function(selection) {
                    var update = _.findIndex($scope.techs, function(tech) {
                        return tech.id == selection;
                    });
                    if (update >= 0) $scope.techs[update].excludeTicked = true;
                });
            });

        $http.get(('api/subscribers/{0}/service/callReasons/list').format($rootScope.user.subscriberId))
        .then(function(list) {
            $scope.callReasons = list.data;

            _.each($scope.widget.instance.settings.callReasons.value, function(selection) {
                var update = _.findIndex($scope.callReasons, function(callReasons) {
                    return callReasons.id == selection;
                });
                if (update >= 0) $scope.callReasons[update].excludeTicked = true;
            });
        });

        serviceCallsService.getServiceCallWorkPerformed(subscriberKey)
            .then((serviceCallWorkPerformed) => {
                $scope.serviceCallWorkPerformed = serviceCallWorkPerformed;

                _.each($scope.widget.instance.settings.serviceCallWorkPerformedKeys.value, function(selection) {
                    var update = _.findIndex($scope.serviceCallWorkPerformed, function(workPerformed) {
                        return workPerformed.serviceCallWorkPerformedKey == selection;
                    });

                    if (update >= 0) {
                        $scope.serviceCallWorkPerformed[update].excludeTicked = true;
                    }
                });
            
            
            });
        
        serviceCallsService.getServiceCallFunctions(subscriberKey)
            .then((serviceCallFunctions) => {
                $scope.serviceCallFunctions = serviceCallFunctions;

                _.each($scope.widget.instance.settings.serviceCallFunctionKeys.value, function(selection) {
                    var update = _.findIndex($scope.serviceCallFunctions, function(serviceCallFunction) {
                        return serviceCallFunction.serviceCallFunctionKey == selection;
                    });

                    if (update >= 0) {
                        $scope.serviceCallFunctions[update].excludeTicked = true;
                    }
                });
            });

        
        $scope.periodIndicators = constantsService.servicePeriodIndicators.enums;
        $scope.serviceRevenueOptions = constantsService.serviceRevenueCategories.enums;

        $scope.openHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };

        $scope.updateTechs = function(model) {
            if (model)
                $scope.widget.instance.settings.techs.value = angular.copy(model);
        };

        $scope.updateCallReasons = function(model) {
            if (model && _.isArray(model))
                $scope.widget.instance.settings.callReasons.value = angular.copy(model);
        };

        $scope.updateServiceCallWorkPerformed = function(model) {
            if (model) {
                $scope.widget.instance.settings.serviceCallWorkPerformedKeys.value = angular.copy(model);
            }
        }

        $scope.updateServiceCallFunctions = function(model) {
            if (model) {
                $scope.widget.instance.settings.serviceCallFunctionKeys.value = angular.copy(model);
            }
        }
    }
]);
