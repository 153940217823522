function alertNumberParameterController(){
    const $ctrl = this;

    $ctrl.$onInit = function() {}
        
}


angular
    .module('angus.components')
    .component('alertNumberParameter', {
        controller: alertNumberParameterController,
        bindings: {
            label: '<',
            name: '@',
            model: '=',
            isRequired: '<',
            isReadOnly: '<'
        },
        template: `
                <label class="control-label" for="$ctrl.name">{{$ctrl.label || 'Label'}} <span class="red" ng-if="$ctrl.isRequired">*</span></label>
                <input type="number" name="$ctrl.name" class="form-control" ng-required="$ctrl.isRequired" ng-disabled="$ctrl.isReadOnly" ng-model="$ctrl.model">`,
    });