angular.module('angus.controllers').controller('subRegSettingsCtrl', [
    '$scope', '$rootScope', '$mdToast', '$http', '$q', 'modalService', 'hierarchyHelperService', 'hierarchyService', 'settingsViews', 'actionViewTemplates', '_', 'constantsService',
    function($scope, $rootScope, $mdToast, $http, $q, modalService, hierarchyHelperService, hierarchyService, settingsViews, actionViewTemplates, _, constantsService) {
        'use strict';

        $scope.filter = {};
        var geocoder = new google.maps.Geocoder();
        function init() {
            $scope.hierarchyTypes = constantsService.hierarchyType.enums;
            $scope.months = constantsService.months.enums;


            $q.all([
                getRunOutTypes()
                .then(function(runOutTypes) {
                    $scope.runOutTypes = runOutTypes;
                    return getSubscriberSettings()
                        .then(function(settings) {
                            $scope.settings = settings;
                        });
                }),
                getWidgets()
                .then(function(widgets) {
                    $scope.widgets = _
                        .chain(widgets)
                        .filter(function(widget) {
                            return widget.settingsIcon;
                        })
                        .groupBy('hierarchyType')
                        .value();
                }),
                getNodesForHierarchyTypes()
                .then(function(hierarchies) {
                    $scope.hierarchy = {};
                    _(hierarchies).forEach(function(hierarchy) {
                        var nodes = hierarchyService.convertDataForEaseOfUse(hierarchy.nodes);

                        $scope.hierarchy[hierarchy.hierarchyType] = {
                            nodes: nodes,
                            currentNodeId: _(nodes).first().id
                        };
                    });
                })
            ]).then(function() {
                $scope.dataLoaded = true;
            });
        }


        $scope.saveFiscalYearStartMonth = function(startMonth) {
            return $http.put(('api/subscribers/{0}/settings/fiscalYearStartMonth').format($scope.subscriberId), {
                fiscalYearStartMonth: startMonth
            });
        };

        $scope.saveBudgetsEnteredUsing = function(budgetsEnteredUsing) {
            return $http.put(('api/subscribers/{0}/settings/budgetsEnteredUsing').format($scope.subscriberId), {
                budgetsEnteredUsing: budgetsEnteredUsing
            });
        };

        $scope.saveActiveMonthSettings = function(monthActivePropane, monthActiveNonPropane) {
            return $http.put(('api/subscribers/{0}/settings/activeAccountMonths').format($scope.subscriberId), {
                monthActivePropane: monthActivePropane,
                monthActiveNonPropane: monthActiveNonPropane
            });
        };

        $scope.saveRunOutSettings = function(potentialRunOutType_Propane, runOutsPercentage_Propane, potentialRunOutType_NonPropane, runOutsPercentage_NonPropane) {
            return $http.put(('api/subscribers/{0}/settings/runOuts').format($scope.subscriberId), {
                potentialRunOutType_Propane: potentialRunOutType_Propane,
                runOutsPercentage_Propane: runOutsPercentage_Propane,
                potentialRunOutType_NonPropane: potentialRunOutType_NonPropane,
                runOutsPercentage_NonPropane: runOutsPercentage_NonPropane
            });
        };

        function getRunOutTypes() {
            return $http.get(('api/subscribers/{0}/settings/runOutTypes').format($scope.subscriberId)).then(function(runOutTypes) {
                return runOutTypes.data;
            });
        }

        function getSubscriberSettings() {
            return $http.get(('api/subscribers/{0}/settings').format($scope.subscriberId)).then(function(settings) {
                return settings.data;
            });
        }

        function getNodesForHierarchyTypes() {
            return $q.all(_.map($scope.hierarchyTypes,
                function(hierarchyType) {
                    return $http
                        .get(('api/subscribers/{0}/hierarchy/nodes?hierarchyType={1}').format($scope.subscriberId, hierarchyType.key))
                        .then(function(nodes) {
                            return {
                                hierarchyType: hierarchyType.key,
                                nodes: nodes.data
                            };
                        });
                }));
        }

        function getWidgets() {
            return $http.get(('api/subscribers/{0}/widgets').format($scope.subscriberId)).then(function(widgets) {
                return widgets.data;
            });
        }

        $scope.getHierarchyName = function(hierarchyType) {
            return constantsService.hierarchyType.get(hierarchyType).value.display;
        };

        $scope.openHierarchyModal = function(hierarchyType, widget) {
            hierarchyService.openModal($scope.hierarchy[hierarchyType].currentNodeId, $scope.hierarchy[hierarchyType].nodes, hierarchyType)
                .then(function(nodeId) {
                    widget.currentNodeId = nodeId;
                });
        };

        function findDiff(original, edited) {
            var diff = {}
            for (var key in original) {
                if (original[key] !== edited[key]) diff[key] = edited[key];
            }
            return diff;
        }

        $scope.openWidgetSettingsModal = function(widget, hierarchyNodeId, hierarchyType) {

            getSettings(widget.id, hierarchyNodeId)
                .then(function(settings) {
                    var url = settingsViews.getWidgetSettingsView(widget.code);

                    if (!url || !settings) return;

                    var scope = {
                        url: url,
                        settings: settings,
                        code: widget.code,
                        id: widget.id,
                        name: widget.name,
                        hierarchyNodeId: hierarchyNodeId,
                        subscriberId: $scope.subscriberId,
                        subscriberKey: $scope.subscriber.abosKey,
                        hierarchyNodes: $scope.hierarchy[hierarchyType].nodes,
                    };

                    modalService
                        .openTopBarModal(actionViewTemplates.subRegEditSettings, scope)
                        .then(function(data) { //[settings, widgetId, hierarchyNodeId]
                            if (data) {
                                var confirmScope = {
                                    title: 'Cascade settings?',
                                    message: 'Do you want these settings to cascade down to all sub-nodes?'
                                };

                                return modalService
                                    .openTopBarModal(actionViewTemplates.subRegSettingsCascadeDialog, confirmScope)
                                    .then(function(response) {
                                        data.push(response);
                                        return data;
                                    });
                            }
                            return data;
                        })
                        .then(function(data) {
                            if (data) saveSettings(settings, data[0], data[1], data[2], hierarchyType, data[3].cascade, data[3].changesOnly);
                        });
                });
        };

        $scope.switchHierarchyType = function(type){
          $scope.activeHierarchyType = type;
        }


        function getSettings(widgetId, hierarchyNodeId) {
            return $http.get(('api/subscribers/{0}/widgets/{1}/settings?hierarchyNodeId={2}').format($scope.subscriberId, widgetId, hierarchyNodeId))
                .then(function(settings) {
                    return settings.data;
                });
        }

        function saveSettings(origSettings, updatedSettings, widgetId, hierarchyNodeId, hierarchyType, cascadeSettings, changesOnly) {
            var urlUnformatted = 'api/subscribers/{0}/widgets/{1}/settings?hierarchyType={2}';
            var url;
            var nodeIds = '';

            var hierarchyNodes = [];

            if (cascadeSettings) {
                var node = _.find($scope.hierarchy[hierarchyType].nodes, function(node) {
                    return node.id == hierarchyNodeId;
                });

                var nodes = hierarchyService.getDescendants($scope.hierarchy[hierarchyType].nodes, node);
                _.forEach(nodes, function(node) {
                    hierarchyNodes.push(node.id);
                });
            }

            hierarchyNodes.push(hierarchyNodeId);
            url = urlUnformatted.format($scope.subscriberId, widgetId, hierarchyType);

            var diffpatcher = jsondiffpatch.create({
                objectHash: function(obj) {
                    return obj;
                }
            });

            var settings = {};
            if (changesOnly) {
                var diff = diffpatcher.diff(origSettings, updatedSettings);
                _.each(_.keys(diff), function(key) {
                    settings[key] = updatedSettings[key];
                });
            } else {
                settings = updatedSettings;
            }

            return $http.put(url, {
                diff: settings,
                hierarchy: hierarchyNodes
            });
        }

        init();

        // HDD Zip Codes logic
        (function() {
            // hold zip codes
            $scope.postalCodeContainer = [];
            // hold location and location id's
            $scope.locationContainer = [];
            // hold non-duplicated locations and location id's
            $scope.nonDuplicateLocationContainer = [];
            // hold location data and latitude & longitude coordinates
            $scope.locationDataContainer = [];
            // hold non-duplicated location data and latitude & longitude coordinates
            $scope.nonDuplicateLocationDataContainer = [];

            $scope.addPostalCode = function() {
                var locationContainer = [];
                var postalCodeContainer = [];
                var localeContainer = [];

                _.forIn($scope.locations, function(location) {
                    locationContainer.push({
                        location: location.text,
                        id: location.id,
                        postalCode: location.postalCode

                    });

                    return locationContainer;
                });

                $scope.area = {
                    location: $scope.locations[0]
                };

                // PostalCode SQL column
                var postalCode = locationContainer[0].postalCode;
                // PostalCodeDescription SQL column
                var city = locationContainer[0].location.slice(locationContainer[0].location.indexOf(' '), locationContainer[0].location.lastIndexOf(',')).trim();
                // PostalCodeCountry SQL column
                var country = locationContainer[0].location.slice(locationContainer[0].location.lastIndexOf(' '), locationContainer[0].location.length).trim();
                var fullLocation = {
                    address: locationContainer[0].location,
                    addressId: locationContainer[0].id
                };

                postalCodeContainer.push(postalCode);
                localeContainer.push(fullLocation);

                _.forEach(localeContainer , function(location) {
                    $scope.locationContainer.push(location);
                });

                _.forEach(postalCodeContainer, function(zip) {
                    if($scope.postalCodeContainer.indexOf(zip) == -1) {
                        $scope.postalCodeContainer.push(zip);
                    }
                    else {
                        $mdToast.show($mdToast.simple().textContent('ZIP CODE ALREADY EXISTS!').position('top right').hideDelay(2500));
                    }
                });
                _.forEach($scope.locationContainer, function(location) {
                    $scope.location = location.address;
                    $scope.locationId = location.addressId;
                });

                getLatitudeLongitude($scope.location, $scope.locationId)
                    .then(function(coordinates) {
                        $scope.locationData = {
                            latitude: coordinates.latitude,
                            longitude: coordinates.longitude,
                            postalCode: postalCode,
                            postalCodeDescription: city,
                            postalCodeCountry: country
                        };

                        $scope.locationDataContainer.push($scope.locationData);
                        removeDuplicateLocationData($scope.locationDataContainer);
                        $scope.$apply()

                    });
            };

            $scope.deletePostalCode = function(zip) {
                $scope.locationDataContainer.splice(zip, 1);
            };

            $scope.clearPostalCodeContainer = function() {
                $scope.locationDataContainer = {};
            };

            $scope.savePostalCodes = function() {
                // function is setup to remove duplicate locations by 'zipCode' before sending data set(s) to database
                savePostalCodes($scope.locationDataContainer, 'postalCode');
            };

            $scope.getSavedZipCodes = function() {
                getSavedZipCodes();
            };

            $scope.getSearchLocation = function(address) {
                geocoder.geocode({'address': address},
                    function (results, status) {
                        $scope.locations = _.map(results, function(suggestion) {
                            var zipcode = suggestion.address_components[0].long_name;
                            for(var i = 0 ; i < suggestion.address_components.length; i++){
                                if(suggestion.address_components[i].types.includes("postal_code")){
                                    zipcode = suggestion.address_components[i].long_name;
                                    break;
                                }
                            }
                            return {
                                id: suggestion.place_id,
                                text: suggestion.formatted_address,
                                postalCode: zipcode
                            };
                        });
                        $scope.$apply()
                    });
            };

            function getLatitudeLongitude(address, suggestionKey) {
                return new Promise( function (resolve, rej){
                    geocoder.geocode({'address': address},
                        function (results, status) {
                            resolve( {longitude: results[0].geometry.location.lng(), latitude: results[0].geometry.location.lat()});
                        })
                })
            }

            function getSavedZipCodes() {
                return $http({
                    method: 'GET',
                    url: ('api/subscribers/{0}/degreeDays/postalCodes').format($scope.subscriberId),
                    data: $scope.locationDataContainer
                })
                .then(function(zipCodes) {
                    $scope.locationDataContainer = zipCodes.data;
                    return $scope.locationDataContainer;
                });
            }

            function savePostalCodes(locationData, removeDuplicatesBy) {
                $scope.nonDuplicateLocationDataContainer = _.uniqBy(locationData, removeDuplicatesBy);
                return $http({
                    method: 'PUT',
                    url: ('api/subscribers/{0}/degreeDays/postalCodes/save').format($scope.subscriberId),
                    data: $scope.nonDuplicateLocationDataContainer
                });
            }

            function removeDuplicateLocationData(locationContainer) {
                var locations = locationContainer,
                    duplicates = [],
                    data,
                    counter = 0;

                while(counter < locations.length) {
                    duplicates.indexOf(data = locations[counter++].postalCode) > -1 ? locations.pop(counter--) : duplicates.push(data);
                }

                return locationContainer;
            }
        })();
    }
]);