'use strict';

angular.module('angus.controllers').controller('datePickerCtrl', ['$scope', 'constantsService', 'moment', function($scope, constantsService, moment) {

	$scope.startDateDate = new Date($scope.startDate);
	$scope.endDateDate = new Date($scope.endDate);

	$scope.convertToMomentDatesArray = function() {
		return [moment.utc($scope.startDateDate), moment.utc($scope.endDateDate)];
	};
}]);
	
