angular.module('angus.services').factory('riderDiscountService', [
    '$http', function ($http) { 
 
        const getRiderDiscounts = function(riderKey) {  
            let url = 'api/internal/rider-discount/by-riderkey/' + riderKey;  

            return $http.get(url, {apiV2: true})
            .then(function (res) { 
                return res.data;
            });
        };   
         
        const createRiderDiscount = function(data) {
            return $http.post(('api/internal/rider-discounts'), data, {apiV2: true})
                .then(function (res) {
                    return res.data;
                });
        };
         
        const updateRiderDiscount = function(riderDiscountKey, data) {
            return $http.put((`api/internal/rider-discounts/${riderDiscountKey}`), data, {apiV2: true})
                .then(function (res) {
                    return res.data;
                });
        };

        return {  
            getRiderDiscounts,
            createRiderDiscount,
            updateRiderDiscount
         };
     }
 ]);
 
 