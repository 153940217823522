angular.module('angus.services').service('toastService', [
    '$timeout',
    function($timeout) {
        let toasts = [];

        this.getToasts = () => {
            return toasts;
        }

        this.addToast = (type, message, timeout = 0) => {
            const toast = {
                type: type,
                message: message
            };

            toasts.push(toast)

            if (timeout) {
                $timeout(() => {
                    const index = toasts.indexOf(toast);
                    if (index !==-1) {
                        toasts.splice(index, 1);
                    }
                }, timeout);
            }
        }

        this.closeToast = (index) => {
            toasts.splice(index, 1);
        }

        this.types = {
            success: 'success',
            info: 'info',
            warning: 'warning',
            error: 'error',
            danger: 'danger'
        };
    }
])