angular.module('angus.controllers').controller('marginToBudgetSettingsController', [
    '$scope', '$rootScope', '$q', 'constantsService', 'budgetsService', 'hierarchyService', '_',
    function($scope, $rootScope, $q, constantsService, budgetsService, hierarchyService, _) {
        'use strict';
        $scope.periodIndicators = constantsService.budgetPeriodIndicators.enums;

        var budgets = new budgetsService($rootScope.user.subscriberId);
        function selectedCategory(categories, categoryIds){
            _.forEach(categories, function(category){
                category.selected = _.some(categoryIds, function(categoryId){ return category.id == categoryId }) ;
            });
        }
        $scope.loadSettings = function(){
            var params = { sortBy: 'name', ascending: 1 };
            $scope.widget.instance.settings.finance ? params.onlyFinance = 1 : null;

            return   $q.all([
                budgets
                    .getProducts(params)
                    .then(function(productCategories){
                        selectedCategory(productCategories, $scope.widget.instance.settings.productCategoryIds ? $scope.widget.instance.settings.productCategoryIds.value : []);
                        $scope.productCategories = productCategories;
                    }),
                budgets
                    .getTradeClasses(params)
                    .then(function(tradeClassCategories){
                        selectedCategory(tradeClassCategories, $scope.widget.instance.settings.tradeClassCategoryIds ? $scope.widget.instance.settings.tradeClassCategoryIds.value : []);
                        $scope.tradeClassCategories = tradeClassCategories;
                    }),
                budgets
                    .getPricePlans(params)
                    .then(function(pricePlanCategories){
                        selectedCategory(pricePlanCategories, $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : []);
                        $scope.pricePlanCategories = pricePlanCategories;
                    })
            ]);
        }
        $scope.loadSettings()

    }
]);