angular.module('angus.controllers').controller('shipmentController', [
    '$scope', '$rootScope', '_', 'shipmentService', 'subscriberService', 'constantsService', 'plannedShipmentService', 'saveAs', 'riderService', 'inventoryManagementService', '$timeout',
        '$uibModal', 'toastService',
    function ($scope, $rootScope, _, shipmentService, subscriberService, constantsService, plannedShipmentService, saveAs, riderService, inventoryManagementService, $timeout,
        $uibModal, toastService) {
        'use strict';
      
        $scope.isLoading = false;  
        $scope.isCreateMode = false; 
        $scope.isEditMode = false;
        $scope.areShipmentHeadersDropdownsLoading = false;
        $scope.areShipmentDropdownsLoading = false;
        $scope.areShipmentsLoading = false; 
        $scope.arePlannedShipmentDropdownLoading = false; 
        $scope.shipmentHeadersDropdown = [];
        $scope.subscribers = []; 
        $scope.shipmentDropdown = [];
        $scope.simCardCarriers = [];
        $scope.selectedSubscriber = null;
        $scope.selectedSubscriberKey = null;
        $scope.includeDeleted = 0;
        $scope.dataUpdated = false;
        $scope.shipmentModel = null;
        $scope.onShipmentResults = [];
        $scope.selectedRiderOrderHeader = [];
        $scope.riderDropdown = [];
        $scope.rowStatus = {
            active: 1,
            delete: 3
        }    
        $scope.deliveryHeaders = [];
        $scope.areOrderTypeDropdownsLoading = false;
        $scope.isPackingSlipLoading = false; 
        $scope.areRiderDropdownsLoading = false;
        $scope.shipmentType = constantsService.shipmentType; 
        $scope.showShipmentOrderTypes = false; 
        $scope.fulfilledQuantityTotal = 0;

        function init() {
            $scope.isLoading = true;
            Promise.all([ 
                getAmsSubscribers()
            ])
            .then(function(results) {
                $scope.isLoading = false; 
            });
        } 
 
        //Public Methods ********************************************************************
        $scope.newShipmentModel = function(subscriberKey, shipmentKey) { 
            $scope.shipmentModel = {  
                riderPlannedShipmentKey : null,
                subscriberKey           : subscriberKey,
                shipmentKey             : shipmentKey, 
                shipmentHeaderKey       : null,
                shipmentCost            : null,
                additionalCost          : null,
                shipmentDate            : new Date(),
                shipmentNotes           : '',
                shipmentDetails         : [], 
                shipmentTrackingID      : null,
                shipmentTypeKey         : null,  
                shipmentID              : null,
                updateUser              : ''
            }
        }

        $scope.newShipmentDetailModel = function() {   
            var shipmentDetail = {
                shipmentDetailKey               : null, 
                riderPlannedShipmentDetailKey   : 0,  
                tankMonitorKey                  : 0,
                shipmentNotes                   : 0, 
                rowStatusKey                    : $scope.rowStatus.active 
            }  
            $scope.shipmentModel.shipmentDetails.push(shipmentDetail);
        } 

        $scope.subscriberChange = function() {  
            $scope.isCreateMode = false;
            $scope.subscriber = _.find($scope.subscribers, function(subscriber){
                return subscriber.abosKey == $scope.selectedSubscriberKey;
            });
            $scope.newShipmentModel($scope.subscriber.subscriberKey);  
            $scope.showShipmentOrderTypes = false;
 
            Promise.all([   
                getSalesTypes(),
                getShipmentsForSubscriber()
            ])
            .then(function(results) { 
                $scope.isLoading = false; 
                  
                $scope.allSearchSelected = true
                $scope.allOnShipmentSelected = true;
                $scope.cancelHandler();
            });
        };    

        $scope.fulfillmentHandler = function(form, od) {  
            $scope.isLoading = true; 
            $scope.shipmentModel.selectedPlannedShipment = od;
            $scope.shipmentModel.subscriber = $scope.subscriber;
            openFulfillmentModal($uibModal, $scope.shipmentModel, form);
        }

        $scope.clickPackingSlipHandler = function() {  
            $scope.isPackingSlipLoading = true;
            $scope.shipmentModel.subscriberKey = $scope.selectedSubscriberKey;
            $scope.shipmentModel.subscriber = _.find($scope.subscribers, function(subscriber) {
                return subscriber.abosKey == $scope.selectedSubscriberKey;
            });
            shipmentService.postReport($scope.shipmentModel)
            .then(function(result) { 
                var date = moment().format('YYYYMMDD');
                var subscriberName = $scope.shipmentModel.subscriber.name.replace(/\s/g, '');
                var fileName = `${subscriberName}_${date}_PackingSlip.pdf`;
                prepareDownload(fileName, result, 'application/pdf'); 
                $scope.isPackingSlipLoading = false; 
            })
            .catch(function(err) { 
                toastService.addToast(toastService.types.danger, 'Failed to generate the packing slip!', 5000); 
                $scope.isPackingSlipLoading = false; 
            });  
        } 

        var prepareDownload = function(filename, data, type) {
            saveAs(
                new Blob([data], { type }),
                filename
            );
        }
        
        //1. get selected shipment - shipmentKey
        //      - get planned shipments id - get planned shipment to get riderKey
        //      - get riders and select by riderKey
        //      - show planned shipment in dropdown? or just show the selected planned shipment
        //2. get shipment order types
        //? 3. get riders (here or get it in #1)
        $scope.shipmentDropDownHandler = function(shipmentKey) {
            const shipmentKeyToUse = shipmentKey ? shipmentKey : $scope.shipmentModel.shipmentKey;
            $scope.isEditMode = true; 
            $scope.allSearchSelected = true
            $scope.allOnShipmentSelected = true;
            $scope.showShipmentOrderTypes = true;
            $scope.addMonitorsToShipmentLoading = false;
            $scope.removeSelectedMonitorShipmentLoading = false;
 
            $scope.newShipmentModel($scope.subscriber.subscriberKey, shipmentKeyToUse);   
            var shipmentDropdownClone = _.cloneDeep($scope.shipmentDropdown);
            var selectedShipment = _.find(shipmentDropdownClone, function(shipment) {
                return shipment.shipmentKey === shipmentKeyToUse;
            });
            $scope.shipmentModel = selectedShipment;  
            if ($scope.shipmentModel.shipmentDate) {
                $scope.shipmentModel.shipmentDate = new Date($scope.shipmentModel.shipmentDate);
            }
            else {
                $scope.shipmentModel.shipmentDate = new Date();
            } 
  
            getShipment(shipmentKeyToUse, $scope.rowStatus.active);
            getRiderHeader();
        }  

        $scope.orderTypeDropDownHandler = function(riderKey) {     
            getRiderHeader(riderKey); 
        }

        $scope.riderDropDownHandler = function() {    
            getSimCardCarriers();
            getRider($scope.shipmentModel.riderKey);   
            getPlannedShipment($scope.shipmentModel.riderKey)
        }  
        
        var getSimCardCarriers = function() {   
            $scope.areCarriersLoading = true; 
            inventoryManagementService.getSIMCardCarriers()
            .then(function(result) { 
                $scope.simCardCarriers = result.data;  
                $scope.areCarriersLoading = false; 
            }); 
        }   

        var getShipment = function(shipmentKey, includeDeleted) { 
            $scope.shipmentModel.shipmentDetails = [];
            shipmentService.getShipmentDetail(shipmentKey, includeDeleted)
            .then(function(result) { 
                $scope.shipmentModel.shipmentDetails = _.map(result, function(rol,index){
                    rol.monitor = false;  
                    rol.actualDeliveryDate = inventoryManagementService.toDate(rol.actualDeliveryDate, 'MM/DD/YYYY'); 
                    rol.refurbishedDate = inventoryManagementService.toDate(rol.refurbishedDate, 'MM/DD/YYYY');   
                    return rol;
                });  
                getActiveShipmentCount(); 

                //get plannedshipments
                if ($scope.shipmentModel.riderPlannedShipmentKey) {
                    plannedShipmentService.getRiderPlannedShipmentsByPlannedShipment($scope.shipmentModel.riderPlannedShipmentKey)
                    .then(function(result) {
                        if (result && result.riderKey) { 
                            $scope.shipmentModel.riderKey = result.riderKey; 
                            getRider($scope.shipmentModel.riderKey);   
                        }
                        getRiderHeader();  
                        $scope.isLoading = false;
                        $scope.showShipmentOrderTypes = true;
                    });
                }
            });
        }

        var getRider = function(riderKey) {    
            $scope.isLoading = true; 
            riderService.getRider(null, riderKey, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {  
                $scope.rider = result;  
                if ($scope.rider && $scope.rider.riderDetail && $scope.plannedShipments && $scope.plannedShipments.length > 0) {
                    $scope.shipmentModel.plannedShipmentDetails = mapOrderLines($scope.plannedShipments[0].plannedShipments, $scope.rider.riderDetail);
                    $scope.searchDataLength = $scope.shipmentModel.plannedShipmentDetails.length; 
                }
                getPlannedShipment(riderKey); 
            }); 
        }   

        var getPlannedShipment = function(riderKey) { 
            plannedShipmentService.getRiderPlannedShipmentByRider(riderKey)
            .then(function(result) {    
                $scope.plannedShipments = result;  
                $scope.plannedShipmentDropDownHandler($scope.shipmentModel.riderPlannedShipmentKey);
                loadPlannedShipmentsDropDown(riderKey); 

                $scope.isLoading = false;
            });
        }

        var mapOrderLines = function(plannedShipmentDetails, riderDetails) {
            var newOrderLines = [];
            if (plannedShipmentDetails && plannedShipmentDetails.length > 0 && riderDetails && riderDetails.length > 0) {
                newOrderLines = _.map(plannedShipmentDetails, function(rol) {
                    var riderDetail = _.find(riderDetails, function(rd) {
                        return rd.riderDetailKey === rol.riderDetailKey;
                    });
                    
                    rol.riderOrderItem = riderDetail ? riderDetail.monitorTypeName : '';
                    rol.riderOrderCarrier = riderDetail ? riderDetail.simCardCarrierKey : '';
                    rol.monitorTypeKey = riderDetail ? riderDetail.monitorTypeKey : ''; 
                    rol.quantityFulfilled = getShipmentDetailQuantity(rol.riderPlannedShipmentDetailKey, $scope.shipmentModel.shipmentDetails);
                    rol.remaining = rol.riderPlannedShipmentDetailQuantity - rol.quantityFulfilled || 0;
                    rol.quantityFulfilledDisaplay = `${rol.quantityFulfilled}/${rol.riderPlannedShipmentDetailQuantity}`;
                    
                    return rol;
                });
            }
            //show only active planned shipment details
            newOrderLines = _.filter(newOrderLines, function(rol) {
                return rol.rowStatusKey == $scope.rowStatus.active;
            });
        
            return newOrderLines;
        }

        var getShipmentDetailQuantity = function(detailKey, shipmentDetails) {
            if (!shipmentDetails || shipmentDetails.length === 0) {
                return 0;
            }
            shipmentDetails = _.filter(shipmentDetails, function(detail) {
                return detail.rowStatusKey == $scope.rowStatus.active;
            });
            return _.filter(shipmentDetails, function(detail) {
                return detail.riderPlannedShipmentDetailKey === detailKey;
            }).length;
        };

        var loadPlannedShipmentsDropDown = function(riderKey) {
            $scope.arePlannedShipmentDropdownLoading = true;
            plannedShipmentService.getRiderPlannedShipmentByRider(riderKey)
                .then(function (result) { 
                    $scope.plannedShipments = result;
                    if (!$scope.plannedShipments.length) {
                        toastService.addToast(toastService.types.danger, 'No planned shipments found', 5000);
                    } 
                    var headers = [];
                    headers = _.filter(result, function (header) {
                        return header.rowStatusKey == $scope.rowStatus.active;
                    });
                    headers = _.map(headers, function (header) {
                        header.orderID = `${header.riderPlannedShipmentKey} (${inventoryManagementService.toDate(header.riderPlannedShipmentDate, 'MM/DD/YYYY')})`;
                        return header;
                    });
        
                    $scope.plannedShipmentDropdown = headers;
                    $scope.selectedRiderOrderHeader = headers;
        
                    getSimCardCarriers();
                    $scope.arePlannedShipmentDropdownLoading = false;
                });
        } 
        
        $scope.plannedShipmentDropDownHandler = function(riderPlannedShipmentKey) {
            const selectedPlannedShipment = _.find($scope.plannedShipments, function(plannedShipment) { 
                return plannedShipment.riderPlannedShipmentKey == riderPlannedShipmentKey;
            });  
            $scope.shipmentModel.plannedShipment = [];
            if (selectedPlannedShipment && $scope.rider && $scope.rider.riderDetail) {
                $scope.shipmentModel.plannedShipment = selectedPlannedShipment; 
                $scope.shipmentModel.plannedShipment.plannedShipmentDetails = mapOrderLines(selectedPlannedShipment.plannedShipmentDetails, $scope.rider.riderDetail); 
            }
        }   

        $scope.submitHandler = function(form, rowStatusKey, isShipped) {
            if ($scope.shipmentModel.shipmentTypeKey === $scope.shipmentType.standard) {
                //If "Standard" is selected as the "Shipment Order Type", then "Order" is required.  
                if ($scope.shipmentModel.riderPlannedShipmentKey) {
                    submitShipment(form, rowStatusKey, isShipped);
                }
                else {  
                    toastService.addToast(toastService.types.danger, 'Cannot submit, Sale Shipment Order Type requires Order.', 5000); 
                }
            }
            else {
                //If "Beta", "Refurbished", or "Replacement" is selected then Order is NOT required
                submitShipment(form, rowStatusKey, isShipped);
            }
        }
 
        var openFulfillmentModal = function($uibModal, model, form) {   
            let riderPlannedShipmentDetailQuantity = 0;

            updatePlannedShipmentDetails();   

            var modalInstance = $uibModal.open({
                component: 'fulfillment',
                backdrop: 'static',
                keyboard: false,
                size: "xl",
                resolve: { 
                    model: model
                }
            });
            modalInstance.result
                .then(function (result) {      
                    if (result.status === 'success') {
                        let selectedMonitors = result.selectedMonitors; 
                        //remove duplicated monitors
                        selectedMonitors = _.uniqBy(selectedMonitors, 'tankMonitorKey');

                        //show only active monitors
                        selectedMonitors = _.filter(selectedMonitors, function(rol) {
                            return rol.rowStatusKey == $scope.rowStatus.active;
                        }); 
                        const fulfillment = selectedMonitors.length;  
                        updatePlannedShipmentDetails(fulfillment); 
 
                        selectedMonitors.forEach(function(rol) {       
                            //check if object exists in shipmentDetails
                            const found = _.find($scope.shipmentModel.shipmentDetails, function(detail){
                                if (detail.tankMonitorKey == rol.tankMonitorKey) {
                                    rol.shipmentDetailKey = detail.shipmentDetailKey;
                                    return rol;
                                }
                            });
                            if (found) {
                                found.rowStatusKey = rol.rowStatusKey;
                            }
                            else {
                                $scope.shipmentModel.shipmentDetails.push({  
                                    actualDeliveryDate: rol.actualDeliveryDate,
                                    monitor: rol.monitor,
                                    monitorInventoryStatusKey: rol.monitorInventoryStatusKey,
                                    monitorInventoryStatusName: rol.monitorInventoryStatusName,
                                    monitorTypeDescription: rol.monitorTypeDescription,
                                    monitorTypeKey: rol.monitorTypeKey,
                                    monitorTypeName: rol.monitorTypeName,
                                    refurbishedDate: rol.refurbishedDate,
                                    serialNumber: rol.serialNumber,
                                    shipmentDetailKey: rol.shipmentDetailKey,   
                                    shippingBoxID: rol.shippingBoxID,
                                    simCardCarrierDescription: rol.simCardCarrierDescription,
                                    simCardCarrierKey: rol.simCardCarrierKey,
                                    simCardCarrierName: rol.simCardCarrierName,
                                    shipmentKey: $scope.shipmentModel.shipmentKey,
                                    riderPlannedShipmentDetailKey: model.selectedPlannedShipment && model.selectedPlannedShipment.riderPlannedShipmentDetailKey ?
                                                                    model.selectedPlannedShipment.riderPlannedShipmentDetailKey : null,
                                    tankMonitorKey: rol.tankMonitorKey,
                                    shipmentDetailNotes: rol.shipmentNotes,
                                    rowStatusKey: rol.rowStatusKey 
                                });  
                            }
                        });  

                        if (selectedMonitors.length > 0) { 
                            form.$setDirty();
                        }

                        getActiveShipmentCount();
                        $scope.isLoading = false;
                    }
                }, function () {
                });
            $timeout(function () { 
                angular.element(document).find('.modal-xl').css('width', '90%');
            }, 0);

            function updatePlannedShipmentDetails(fulfillmentCount) {
                if ($scope.shipmentModel.plannedShipment &&
                    $scope.shipmentModel.plannedShipment.plannedShipmentDetails &&
                    $scope.shipmentModel.plannedShipment.plannedShipmentDetails.length > 0) { 
                     
                    const plannedShipmentDetail = _.find($scope.shipmentModel.plannedShipment.plannedShipmentDetails, function (rol) {
                        return rol.riderPlannedShipmentDetailKey == model.selectedPlannedShipment.riderPlannedShipmentDetailKey;
                    });

                    riderPlannedShipmentDetailQuantity = plannedShipmentDetail.riderPlannedShipmentDetailQuantity || 0;  
                    if (fulfillmentCount) {
                        plannedShipmentDetail.quantityFulfilled = fulfillmentCount;
                    }
                    plannedShipmentDetail.remaining = riderPlannedShipmentDetailQuantity - plannedShipmentDetail.quantityFulfilled || 0;   
                    plannedShipmentDetail.quantityFulfilledDisaplay = `${plannedShipmentDetail.quantityFulfilled}/${riderPlannedShipmentDetailQuantity}`;
                }
            }
        }
 
        //count shipmentDetails that are active
        var getActiveShipmentCount = function() {
            if ($scope.shipmentModel.shipmentDetails && $scope.shipmentModel.shipmentDetails.length > 0) {
                $scope.fulfilledQuantityTotal = _.filter($scope.shipmentModel.shipmentDetails, function (detail) {
                    return detail.rowStatusKey == $scope.rowStatus.active;
                }).length;
            }
            else{
                $scope.fulfilledQuantityTotal = 0;
            }
        }

        //do NOT change the status of the monitors. if changeStatusOfMonitor is true
        var submitShipment = function(form, rowStatusKey, isShipped) {
            $scope.shipmentSubmitLoading = true;  
            if ($scope.selectedSubscriberKey) {
                $scope.shipmentModel.isShipped = isShipped ? true : false;
                $scope.shipmentModel.subscriberKey = $scope.selectedSubscriberKey;
                $scope.shipmentModel.rowStatusKey = rowStatusKey;  
                $scope.shipmentModel.insertUser = $rootScope.user.username;  
                $scope.shipmentModel.updateUser = $rootScope.user.username; 

                if ($scope.shipmentModel.plannedShipment && 
                    $scope.shipmentModel.plannedShipment.plannedShipmentDetails && $scope.shipmentModel.plannedShipment.plannedShipmentDetails.length > 0) {
                        $scope.shipmentModel.shipmentDetails.forEach(function(rol) {      
                        const found = _.find($scope.shipmentModel.shipmentDetails, function(detail){
                            if (detail.tankMonitorKey == rol.tankMonitorKey) {   
                                detail.shipmentDetailKey = rol.shipmentDetailKey;
                                return detail;
                            }
                        });
                        if(!found) {
                            $scope.shipmentModel.shipmentDetails.push({  
                                shipmentKey: $scope.shipmentModel.shipmentKey,
                                riderPlannedShipmentDetailKey: rol.riderPlannedShipmentDetailKey,
                                tankMonitorKey: rol.tankMonitorKey,
                                shipmentDetailNotes: '',
                                shipmentDetailKey: rol.shipmentDetailKey,
                                rowStatusKey: $scope.shipmentModel.rowStatusKey
                            }); 
                        }
                    });
                }

                shipmentService.postShipment($scope.shipmentModel)
                .then(function(result) {
                    var shipmentKey = result;
                    $scope.shipmentModel.shipmentKey = shipmentKey;
                    if (shipmentKey > 0) {  
                        if (rowStatusKey == $scope.rowStatus.active) { 
                            toastService.addToast(toastService.types.success, 'Successfully Saved the Shipment.', 5000); 
                        }
                        else {  
                            toastService.addToast(toastService.types.success, 'Successfully Deleted the Shipment!', 5000); 
                        }
                        if (form) {
                            form.$setPristine();  
                        }  
                        getShipmentsForSubscriber(shipmentKey);
                    }
                    else {  
                        toastService.addToast(toastService.types.danger, 'Failed to Submit, Please check your data!', 5000); 
                    } 
                    getActiveShipmentCount();
                })
                .catch(function(err) { 
                    toastService.addToast(toastService.types.danger, 'Failed to Submit, Please check your data!', 5000); 
                }); 
 
                $scope.shipmentSubmitLoading = false;
                $scope.isCreateMode = false; 
                $scope.isEditMode = true; 
            }
            else {  
                toastService.addToast(toastService.types.danger, 'Select a subscriber', 5000); 
            }
        }

        $scope.reset = function() {  
            $scope.isLoading = false;
            $scope.fulfilledQuantityTotal = 0;
            $scope.isEditMode = false; 
        }

        $scope.clickAddShipmentHandler = function(form) {    
            const shipmentKey = null;
            $scope.shipmentDropdown = [{ shipmentKey: shipmentKey,  shipmentID: '<--- Create New --->' }];
            $scope.showShipmentOrderTypes = true;
            $scope.isCreateMode = true;
            $scope.reset();  
            $scope.newShipmentModel($scope.selectedSubscriberKey, shipmentKey);
            form.$setDirty(); 
        }

        $scope.shipmentDeletedHandler = function() {     
            getShipmentsForSubscriber();     
        }  

        $scope.cancelHandler = function(form, loadHeader) {  
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            } 
                   
            $scope.shipmentModel = null; 
            $scope.isCreateMode = false; 
            $scope.showShipmentOrderTypes = false; 
            $scope.reset();  
 
            if (loadHeader) {
                getShipmentsForSubscriber();
            }
        }

        $scope.clickDeleteOrder = function(formOrder, rowStatus) {
            if(confirm("Are you sure to delete the shipment?")) {
                $scope.submitHandler(formOrder, rowStatus);
            }  
        }

        //Private Methods ********************************************************************
        var getRiderHeader = function(riderKey) {    
            $scope.areRiderDropdownsLoading = true;
            return riderService.getRiderHeader(null, riderKey, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {   
                var headers = [];
                if ($scope.includeDeleted) {
                    headers = result;
                }
                else {
                    headers = _.filter(result, function(header) {
                        return header.rowStatusKey == $scope.rowStatus.active;
                    }) 
                }
                headers = _.map(headers, function(header){
                    header.riderID = `${header.riderID} (${inventoryManagementService.toDate(header.riderCreationDate, 'MM/DD/YYYY')})`; 
                    return header;
                });

                $scope.riderDropdown = headers;
                $scope.areRiderDropdownsLoading = false;
            }); 
        } 

        var getAmsSubscribers = function() {
            $scope.areSubscriberDropdownsLoading = true;
            subscriberService.getAmsSubscribers()
            .then(function(subscribers){
                $scope.subscribers = _.filter(subscribers, function(subscriber){
                    return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                }); 
                $scope.areSubscriberDropdownsLoading = false;
            }); 
        } 

        var getShipmentsForSubscriber = function(shipmentKey) {    
            $scope.areShipmentDropdownsLoading = true;
            const includeDeletedValue = $scope.includeDeleted && $scope.includeDeleted == 1 ? 1 : 0;  
            return shipmentService.getShipmentsForSubscriber($scope.selectedSubscriberKey, includeDeletedValue)
            .then(function(headers) {   
                $scope.areShipmentDropdownsLoading = false;
                
                headers = _.map(headers, function(header){ 
                    const riderID = header.riderID || '';
                    header.shipmentID = `${inventoryManagementService.toDate(header.shipmentDate, 'MM/DD/YYYY')}`;
                    if (riderID) {
                        header.shipmentID += ` (RiderID: ${riderID})`;  
                    }
                    const shipped = header.shippedFlag ? ' [Shipped]' : '';
                    header.shipmentID += `${shipped}`;  

                    return header;
                });

                headers = _.orderBy(headers, ['shipmentDate', 'riderPlannedShipmentKey'], ['asc', 'asc']);
                $scope.shipmentDropdown = headers;
                if (shipmentKey) {
                    $scope.shipmentDropDownHandler(shipmentKey);
                }
            }); 
        }  

        var getSalesTypes = function() {
            return inventoryManagementService.getSaleTypes()
            .then(function(result) {   
                $scope.salesTypes = result;   
            }); 
        }
 
        init(); 
    }
]);

