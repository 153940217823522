angular.module('angus.controllers').controller('alertResultsWidgetController', [
    '$q', '$rootScope', '$scope','actionViewTemplates', 'alertsFrameworkService', 'modalService',
    function($q, $rootScope, $scope, actionViewTemplates, alertsFrameworkService, modalService) {
        const subscriberKey = $rootScope.user.subscriberAbosKey;

        function loadWidget() {
            const alertKey = $scope.widget.instance.settings.alertKey;

            if (!alertKey) {
                return $scope.widget.promises.monitor($q.resolve());
            }

            const promise = alertsFrameworkService.getAlertResultsCount(subscriberKey, alertKey)
                .then(resultsCount => {
                    $scope.resultsCount = resultsCount;
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);    

        $scope.$on('widgetSettingsChanged', function() {
            loadWidget();
        });

        $scope.resultsClicked = () => {
            const scope = {
                widgetName: 'Alert Results',
                widgetIcon: `table`,
                widgetId: 'AlertResults',
                widgetCode: 'AlertResults',
                settings: {},
                model: {
                    subscriberKey: subscriberKey, 
                    alertKey: $scope.widget.instance.settings.alertKey
                }
            };

            modalService.openActionView(actionViewTemplates.alertResults, scope, 'AlertResults');
        }

    }
])