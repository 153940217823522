angular.module('angus.services').factory('fulfillmentService', [
    '$http', '$q', function ($http, Q) {  
        
        const getMonitorSearch = function(monitorTypeKey, shippingBoxID, deliveryDate, serialNumber, monitorInventoryStatusKey, simCardCarrierKey) {
            let url =  'api/tankMonitors/shipments/monitorsearch?';          
            if (monitorTypeKey) {
                url += 'monitorTypeKey=' + monitorTypeKey + '&';
            }
            if (shippingBoxID) {
                url += 'shippingBoxID=' + shippingBoxID + '&';
            }
            if (deliveryDate) {
                url += 'deliveryDate=' + deliveryDate + '&';
            }  
            if (serialNumber) {
                url += 'serialNumber=' + serialNumber + '&';
            } 
            if (monitorInventoryStatusKey) {
                url += 'monitorInventoryStatusKey=' + monitorInventoryStatusKey + '&';
            } 
            if (simCardCarrierKey) {
                url += 'simCardCarrierKey=' + simCardCarrierKey;
            } 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        } 
        return {  
            getMonitorSearch
         };
     }
 ]);
 
 