angular.module('angus.directives')
    .directive('filters', ['_', function(_) {
        'use strict';

        var template =
            `<div class="panel">

                <div class="panel-heading clickable" data-ng-click="toggleFilter()">
                    <div data-ng-if="!showFilter">
                        + Settings <i class="pull-right glyphicon glyphicon-chevron-right"></i>
                    </div>
                    <div data-ng-if="showFilter">
                        - Settings <i class="pull-right glyphicon glyphicon-chevron-down"></i>
                    </div>
                </div>
                
                <div class="panel-body" data-slide-toggle="showFilter">
                    <div class="row" ng-if="savedSettingsDisabled">
                        <div class="col-md-12">
                    <div ng-transclude></div>
                </div>
                    </div>
                    <div class="row" ng-if="!savedSettingsDisabled">
                        <div class="col-md-9">
                            <div ng-transclude></div>
                        </div>
                        <div class="col-md-3 form-group">
                            <div subscriber-id="subscriberId" widget-code="widgetCode" saved-filters="filterChanged(filter)" filter="ngModel" grid="grid"></div>
                        </div>
                    </div>
                    <div class="panel-footer">
                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-primary pull-right" data-ng-click="apply()" ng-disabled="applyDisabled">
                                Apply Settings
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;

        return {
            restrict: 'A',
            scope: {
                ngModel: '=',
                showByDefault: '=',
                applyFn: '&',
                subscriberId: '=',
                grid: '=',
                widgetCode: '=',
                applyDisabled: '=',
                savedSettingsDisabled: '=',
                disableAutoApply: '='
            },
            transclude: true,
            controller: ['$scope', '$timeout', 'promiseMonitor', function($scope, $timeout, promiseMonitor) {
                $scope.showFilter = $scope.showByDefault || false;
                $scope.originalFilter = _.cloneDeep($scope.ngModel);

                $scope.toggleFilter = function() {
                    $scope.showFilter = !$scope.showFilter;
                };

                $scope.apply = function(filter) {
                    if ($scope.applyFn){
                        if (!$scope.applyDisabled) {
                            $scope.applyFn({filter: filter});
                        }
                    }
                };

                $scope.filterChanged = function(filter) {
                    return $timeout(function() {
                        $scope.ngModel = _.cloneDeep(filter || $scope.originalFilter());
                        $scope.$apply(filter);
                    }, 1)
                    .then(function() {
                        if (!$scope.disableAutoApply) {
                            $scope.apply(filter);
                        }
                    });
                };


            }],
            template: template
        };
    }]);