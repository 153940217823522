angular.module('angus.services').factory('inventoryManagementService', [
    '$http', function ($http) {
  
         const getVendors = function() {
             return $http.get(('api/tankMonitors/vendors')); 
         }; 
          
         const getSaleTypes = function() {
            return $http.get(('api/tankMonitors/sales/types'))
            .then(function(result) {
                return result.data;
            });
         }; 

         const getSIMCardCarriers = function() {
             return $http.get(('api/tankMonitors/simCardCarriers'));
         }; 
         
         const getInventoryItems = function(vendorKey) {
             return $http.get(('api/tankMonitors/inventoryItems/' + vendorKey));
         }; 

         const getMonitorInventoryStatus = function() {
            return $http.get(('api/tankMonitors/monitorInventoryStatus'));
         }; 

         const postInventoryItems = function(data) {
            return $http.post(('api/tankMonitors/inventoryItems'), data);
         };  
         
         const toDate = function(date, format) { 
            if (!date) {
                return null;
            }
            //remove time - get invalid object error if time is not removed
            if (date.includes('T')) {
                date = date.split('T')[0];
            }
            else {
                //removing 'z' from the date string to make it work with utc library
                date = date ? date.slice(0, -1) : null;
            }
            if (format) {
                date = moment(date).format(format);
                return date;
            }
            else { 
                return date ? moment(date).toDate() : null; 
            }
        }

         const getMonitorTypes = function(subscriberId) {
            var opts = { method : 'GET', url : 'api/subscribers/{0}/containers/monitors/monitorTypes'.format(subscriberId)};
            return $http(opts)
                .then(function(result) { 
                    return result.data;
                });
        }

        const getFinancingTypes = function() { 
            let url =  'api/tankMonitors/finance/types'; 
            return $http.get((url))
               .then(function (res) {
               return res.data;
           });
        };  

        return {
            toDate,
            getMonitorTypes,
            getInventoryItems,
            getVendors,
            getSaleTypes,
            getSIMCardCarriers,
            getMonitorInventoryStatus,
            getFinancingTypes, 
            postInventoryItems
        };
     }
 ]);
 
 