angular
  .module('angus.utils')
  .factory('colDefService', ['$filter', '_', 'moment', '$document',  function ($filter, _, moment, $document) {
    'use strict';


	function createColDef (colId, headerName, field, colOpts, renderOpts){
		var colDef = _.assign({}, colOpts);
		colDef.headerName = headerName;
		colDef.field = field;
		colDef.colId = colId;
		if (renderOpts && renderOpts.width) {
			colDef.width = renderOpts.width;
		}
		return colDef;
	}

	function renderer(value, decimalPlaces){

		var display = value;
		if(value!== null && value !== undefined)
		display = $filter('number')(Math.abs(value), decimalPlaces);


		function numberDecorator(newDisplay){
		  	var text = newDisplay || display,
          	clazz = '';

		      if(value < 0) {
		        clazz = 'text-danger';
		        text  = '(' + text + ')';
		      } else if(value === 0) {
		        clazz = 'text-black';
		      }

		      if(clazz) {
		        return '<span class="' + clazz + '">' + text + '</span>';
		      }
		      return text;
		}

		function returnDisplay(newDisplay){
			switch(display){
				case null:
					return 'n/a';
				case undefined:
				case '':
					return '';
				default :
					return newDisplay;
			}
		}

		return {
			prepend : function(prefix){

				return returnDisplay(numberDecorator(prefix + display));
			},
			append : function(suffix){
				return returnDisplay(numberDecorator(display + suffix));
			},
			toString : function(){
				return returnDisplay(numberDecorator());
			}
		};
	}

	function createNumberRenderer(renderOpts){
  		return function(params) {
  			if(params.node.children && !params.value && params.value != 0)
  				return '';
  			return renderer(params.value, renderOpts.decimalPlaces).toString();
  		}
    }

    function createCurrrencyRenderer(renderOpts){
  		return function(params) {
  			if(params.node.children && !params.value && params.value != 0)
  				return '';
  			return renderer(params.value, renderOpts.decimalPlaces).prepend(renderOpts.symbol || '$');
  		}
    }

    function createPercentRenderer(renderOpts){
  		return function(params) {
  			if(params.node.children && !params.value && params.value != 0)
  				return '';
  			return renderer(params.value, renderOpts.decimalPlaces).append(renderOpts.symbol || '%');
  		}
    }

    function createBooleanRenderer(count) {
    	return function(params){
    		if(count && params.node.children && (params.data[params.colDef.colId] || params.data[params.colDef.colId] == 0))
    			return renderer(params.data[params.colDef.colId], 0);
    		switch(params.value){
				case 'Yes':
					return '<i class="fa fa-check text-success"></i>';
				default :
					return '';
			}  /// Make check icon after you find out if it works
    	}
	}

	function createBooleanRendererCheckbox(count, cb, hide) {
		return function(params){
			if(count && params.node.children && (params.data[params.colDef.colId] || params.data[params.colDef.colId] == 0))
    			return renderer(params.data[params.colDef.colId], 0);

    		if(hide(params)) return '';

			var eCheckbox 	= $document[0].createElement('input');
			eCheckbox.type 	= 'checkbox';
			eCheckbox.checked  = params.value == 'Yes';
			eCheckbox.style.marginTop = "-10px"; //fix checkbox cut-off  
			var dataValue = params.data ? params.data[params.colDef.field] : null;
			if (dataValue && dataValue.disabled) {
				eCheckbox.disabled = true;
			}
			eCheckbox.addEventListener('change', function () {
				var newValue = eCheckbox.checked;
				params.data[params.colDef.field] = newValue;
				if(cb) cb(params, newValue);
		    }); 

			return eCheckbox;
		}
	}
 
	function createRendererTextarea(cb, optionData) {
		return function(params){
			var eTextarea 	= $document[0].createElement('textarea'); 
			eTextarea.rows = 4;  
			eTextarea.cols = 50;  
			eTextarea.value = params.value;

			var dataValue = params.data[params.colDef.field];
			if (dataValue && dataValue.disabled) {
				eTextarea.disabled = true;
			}
			eTextarea.addEventListener('change', function () {
				var newValue = eTextarea.value;
				params.data[params.colDef.field] = newValue;
				if(cb) cb(params, newValue);
		    }); 
			return eTextarea;
		}
	}

	function booleanComparator(value1, value2) {
	  var value1Ordinal = value1==='Yes' ? 0 : (value1==='No' ? 1 : 2);
	  var value2Ordinal = value2==='Yes' ? 0 : (value2==='No' ? 1 : 2);
	  return value1Ordinal - value2Ordinal;
	}


	function booleanFilterCellRenderer(params) {
	    var value = params.value;
	    if (value==='Yes') //this is the unicode for tick character
	       	return '<i class="fa fa-check text-success"></i> Yes';
	     else if (value === 'No')  //this is the unicode for cross character
	        return '&#10006; No';
	    else
	        return '(empty)';
	}

	function dateComparator(date1, date2) {
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);

		if (date1Number===null && date2Number===null)
			return 0;
		if (date1Number===null)
			return -1;
		if (date2Number===null)
			return 1;

		return date1Number - date2Number;
	} 

  // Generate a comparator for two date-time values
  // for a given format - E.G. h:mm A
  function getTimeComparatorForFormat(format) {
    return function (timeA, timeB) {
      var momentA = moment(timeA, [format]);
      var momentB = moment(timeB, [format]);
      return momentA.diff(momentB);
    };
  }

	//MM/DD/YYYY
	function monthToComparableNumber(date) {
	    if (date === undefined || date === null ||!date.includes("/")) {
	        return null;
	    }

		// 11/10/2020
		var yearNumber,monthNumber,dayNumber
		if(date.length == 10){
			yearNumber  = date.substring(6,10);
			monthNumber = date.substring(0,2);
			dayNumber   = date.substring(3,5);
		}else if(date.length == 8){
			// 1/2/2020
			yearNumber  = date.substring(4,8);
			monthNumber = date.substring(0,1);
			dayNumber   = date.substring(2,3);
		}else if(date.length == 9 && date.split("/")[0].length == 2){
			// 10/1/2020
			yearNumber  = date.substring(5,9);
			monthNumber = date.substring(0,2);
			dayNumber   = date.substring(3,4);
		}else if(date.length == 9 && date.split("/")[1].length == 2){
			// 1/10/2020
			yearNumber  = date.substring(5,9);
			monthNumber = date.substring(0,1);
			dayNumber   = date.substring(2,4);
		}else{
			return null
		}


		var result = (yearNumber*10000) + (monthNumber*100) + dayNumber;
	    return result;
	}

    function createNumberClass(renderOpts){
  		return function(params) {
  			var classes = renderOpts.center ? ['center'] : ['text-right'];
  			if ( params.value < 0 )
  				classes.push('text-danger');
        	else if(renderOpts.colorPositive && params.value > 0)
          		classes.push('text-success');

  			return classes;
  		}
    }

    function getNumberValue(params){
		return _.reduce(params.colDef.field.split('.'), function(result, key){
			if(!result) return null
			return result[key]
		}, params.data)
	}

	function createRendererSelect(cb, hide, optionData) {
		return function(params){
    		if(hide(params)) return '';

			var eSelect = $document[0].createElement('select');  
			eSelect.id = params.colDef.colId;  
			eSelect.style.width = params.eGridCell.style.width;
			eSelect.multiple = params.column.colDef.multiple || false; 
			
			for (var i = 0; i < optionData.length; i++) {
				var option = document.createElement("option");
				option.value = optionData[i].value;
				option.text = optionData[i].text;
				eSelect.appendChild(option);
			}  
			//set the selected from DB
			if (eSelect.options && params.value && eSelect.options[params.value - 1]) {
				eSelect.options[params.value - 1].selected = true;
			}
			//update the selected value by a user
			eSelect.onchange=function(e){
			    var newValue = eSelect.options.selectedIndex;
				params.data[params.colDef.field] = newValue + 1;
				if(cb) cb(params, newValue);
			};

			return eSelect;
		}
	}

	return {
		createText : createColDef,
		createNumber : function(colId, headerName, field, colOpts, renderOpts){
	        renderOpts           = renderOpts || {};
	        colOpts              = _.assign({}, colOpts);
	        var origOpts = {
	        	valueGetter  : getNumberValue
	    	};
	        colOpts              = _.assign(origOpts, colOpts);
	        colOpts.cellRenderer = createNumberRenderer(renderOpts);
	        colOpts.cellClass    = createNumberClass(renderOpts);
	        colOpts.filter       = 'number';
	        colOpts.filterParams = {suppressRemoveEntries : true};
	        return createColDef(colId, headerName, field, colOpts);
		},
		createCurrency : function(colId, headerName, field, colOpts, renderOpts){
	        renderOpts           = renderOpts || {};
	        var origOpts = {
	        	valueGetter  : getNumberValue
	    	};
	        colOpts              = _.assign(origOpts, colOpts);
	        colOpts.cellRenderer = createCurrrencyRenderer(renderOpts);
	        colOpts.cellClass    = createNumberClass(renderOpts);
	        colOpts.filter       = 'number';
	        colOpts.filterParams = {suppressRemoveEntries : true};
			return createColDef(colId, headerName, field, colOpts);
		},
		createPercentage : function(colId, headerName, field, colOpts, renderOpts){
	        renderOpts           = renderOpts || {};
	        var origOpts = {
	        	valueGetter  : getNumberValue
	    	};

	        colOpts              = _.assign(origOpts, colOpts);
	        colOpts.cellRenderer = createPercentRenderer(renderOpts);
	        colOpts.cellClass    = createNumberClass(renderOpts);
	        colOpts.filter       = 'number';
	        colOpts.filterParams = {suppressRemoveEntries : true};
			return createColDef(colId, headerName, field, colOpts);
		},
		createDate : function(colId, headerName, field, colOpts, renderOpts){
			colOpts = _.assign({}, colOpts);
	        colOpts.filter       = 'set';
	        colOpts.filterParams = {newRowsAction: 'keep', suppressRemoveEntries : true}; 
	        colOpts.comparator   = dateComparator; 
			colOpts.cellClass = function(){
				return  ['text-center'];
			};
			return createColDef(colId, headerName, field, colOpts, renderOpts);
		},
		createDateNG: function(colId, headerName, field, colOpts, renderOpts){
			colOpts = _.assign({}, colOpts);
	        colOpts.filter       = 'set';
	        colOpts.filterParams = {newRowsAction: 'keep', suppressRemoveEntries : true}; 
			colOpts.cellClass = function(){
				return  ['text-center'];
			};
			colOpts.cellRenderer = function(params) {
				if (params.value) {
					const date = new Date(params.value);
					return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${
						date.getDate().toString().padStart(2, '0')}/${
						date.getFullYear()}`;
				}
				return '';
			}
			return createColDef(colId, headerName, field, colOpts, renderOpts);
		},
		createTime : function(colId, headerName, field, format, colOpts) {
			colOpts = _.assign({}, colOpts);
			colOpts.comparator   = getTimeComparatorForFormat(format);
			return createColDef(colId, headerName, field, colOpts);
		},
		createBoolean : function(colId, headerName, field, renderOpts, colOpts){
			renderOpts = renderOpts || {};
	        colOpts              = _.assign({}, colOpts);
	        colOpts.valueGetter  = function(params){ 
	        	var value = params.data && params.data[field]
	        	if (value==='true' || value===true || value === 1)
			     	return 'Yes';
			  	else if (value==='false' || value===false || value === 0)
			      	return 'No';
				else if (value === 'hide') {
					renderOpts.hide = true;
					renderOpts.edit = false;
					colOpts.cellRenderer = null;
					return 'No';
				}
			  	else 
			      return null;
	        };

	        if(!!renderOpts.edit)
	        	colOpts.cellRenderer = createBooleanRendererCheckbox(renderOpts.count, renderOpts.onChange, renderOpts.hide);
	        else
	        	colOpts.cellRenderer = createBooleanRenderer(renderOpts.count);

	        colOpts.comparator   = booleanComparator;
	        colOpts.filter       = 'set';
	        colOpts.filterParams = {
	        	newRowsAction: 'keep',
	        	values: ['Yes','No'],
	        	cellRenderer: booleanFilterCellRenderer
	        };
			colOpts.cellClass = function(){
				return  ['text-center'];
			};

			return createColDef(colId, headerName, field, colOpts);
		},
		createYearMonth : function(colId, headerName, field, colOpts, renderOpts) {
			colOpts = _.assign({}, colOpts);
			renderOpts = renderOpts || {};

			const dateFormat = renderOpts.format || 'yyyy-MMM';

			function yearMonthComparator(a, b){
				const dateA = new Date(a);
				const dateB = new Date(b);

				if (dateA.toString() === 'Invalid Date' || dateB.toString() === 'Invalid Date') {
					return null;
				}

				return dateA.getTime() - dateB.getTime();
			}

			function yearMonthCellRenderer(params){
				if (params.value){
					const date = new Date(params.value);

					if (date.toString() === 'Invalid Date') {
						return null;
					}

					return $filter('date')(date, dateFormat);	
				}
				
				return null;
			};

			colOpts.cellRenderer = yearMonthCellRenderer;

			colOpts.comparator = yearMonthComparator;

			colOpts.filterParams = {
				cellRenderer: yearMonthCellRenderer,
				comparator: yearMonthComparator
			};

			return createColDef(colId, headerName, field, colOpts);
		},
		createMonth: function(colId, headerName, field, colOpts, renderOpts) {
			colOpts = _.assign({}, colOpts);
			renderOpts = renderOpts || {};

			const sortedMonths = ['January', 'February', 'March', 'April', 'May', 'June',
				'July', 'August', 'September', 'October', 'November', 'December'];

			colOpts.valueGetter = function(params) {				
				const value = params.data && params.data[field];

				const dateValue = new Date(value);

				if (dateValue.toString() !== 'Invalid Date'){
					const monthIndex = dateValue.getMonth() + 1 /*Use an Index of 1 - 12 since that is what will be exported to CSV */
					return monthIndex;
				} else {
					return null;
				}
			};

			function monthCellRender (params) {
				return sortedMonths[params.value - 1];
			}

			colOpts.cellRenderer = monthCellRender;

			colOpts.filterParams = {
				cellRenderer: monthCellRender,
				comparator: (a, b) => a - b
			}

			return createColDef(colId, headerName, field, colOpts);
		},
		createYear: function(colId, headerName, field, colOpts, renderOpts) {		
			colOpts = _.assign({}, colOpts);
			renderOpts = renderOpts || {};

			colOpts.valueGetter = function(params) {
				const value = params.data && params.data[field];
				const dateValue = new Date(value);

				if (dateValue.toString() !== 'Invalid Date'){
					return dateValue.getFullYear();
				} else {
					return null;
				}
			}

			colOpts.filterParams = {
				comparator: (a, b) => a - b
			}

			return createColDef(colId, headerName, field, colOpts);
		},
		createSelect : function(colId, headerName, field, renderOpts, colOpts, optionData) {  
			renderOpts = renderOpts || {};
	        colOpts    = _.assign({}, colOpts);
			
			if(colOpts.hide) return ''; 
			
			colOpts.cellRenderer = createRendererSelect(renderOpts.onChange, renderOpts.hide, optionData);
	    
			return createColDef(colId, headerName, field, colOpts);
		},
		createTextarea : function(colId, headerName, field, renderOpts, colOpts, optionData) {
			renderOpts = renderOpts || {};
	        colOpts    = _.assign({}, colOpts);  
			
			if(colOpts.hide) return ''; 
			
			colOpts.cellRenderer = createRendererTextarea(renderOpts.onChange, optionData);
	    
			return createColDef(colId, headerName, field, colOpts);
		}
	};

}]);
