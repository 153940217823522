angular.module('angus.controllers').controller('serviceCallsSubRegSettingsCtrl', [
    '$scope', '$http', '_', 'constantsService', 'serviceCallsService', 
    function($scope, $http, _, constantsService, serviceCallsService) {

        'use strict';

        $scope.filter = {};
        
        $http.get(('api/subscribers/{0}/service/techs').format($scope.subscriberId))
            .then(function(list){
                $scope.techs = list.data;

                _.each($scope.settings.techs.value, function(selection){
                    var update = _.findIndex($scope.techs, function(tech){
                            return tech.id == selection;
                        });
                    if(update >= 0) $scope.techs[update].excludeTicked = true;
                });
            });

        $http.get(('api/subscribers/{0}/service/callReasons/list').format($scope.subscriberId))
            .then(function(list){
                $scope.callReasons = list.data;

                _.each($scope.settings.callReasons.value, function(selection){
                    var update = _.findIndex($scope.callReasons, function(callReasons){
                            return callReasons.id == selection;
                        });
                    if(update >= 0) $scope.callReasons[update].excludeTicked = true;
                });
            });

            serviceCallsService.getServiceCallWorkPerformed($scope.subscriberKey)
            .then((serviceCallWorkPerformed) => {
                $scope.serviceCallWorkPerformed = serviceCallWorkPerformed;

                _.each($scope.settings.serviceCallWorkPerformedKeys.value, function(selection) {
                    var update = _.findIndex($scope.serviceCallWorkPerformed, function(workPerformed) {
                        return workPerformed.serviceCallWorkPerformedKey == selection;
                    });

                    if (update >= 0) {
                        $scope.serviceCallWorkPerformed[update].excludeTicked = true;
                    }
                });
            
            
            });
        
        serviceCallsService.getServiceCallFunctions($scope.subscriberKey)
            .then((serviceCallFunctions) => {
                $scope.serviceCallFunctions = serviceCallFunctions;

                _.each($scope.settings.serviceCallFunctionKeys.value, function(selection) {
                    var update = _.findIndex($scope.serviceCallFunctions, function(serviceCallFunction) {
                        return serviceCallFunction.serviceCallFunctionKey == selection;
                    });

                    if (update >= 0) {
                        $scope.serviceCallFunctions[update].excludeTicked = true;
                    }
                });
            });

        $scope.periodIndicators      = constantsService.servicePeriodIndicators.enums;
        $scope.serviceRevenueOptions = constantsService.serviceRevenueCategories.enums;

        $scope.closeTechs = function(){
            $scope.settings.techs.value = angular.copy($scope.filter.techs);
        };

        $scope.closeServiceCallWorkPerformed = function(){
            $scope.settings.serviceCallWorkPerformedKeys.value = angular.copy($scope.filter.serviceCallWorkPerformedKeys);
        };

        $scope.closeServiceCallFunctions = function(){
            $scope.settings.serviceCallFunctionKeys.value = angular.copy($scope.filter.serviceCallFunctionKeys);
        };
    }
]);