angular.module('angus.directives')
    .directive('fancyGrid', [ '$compile',
        function($compile) {
        	'use strict';

            return {
                scope: {
                    grid         : '=fancyGrid',
                    gridStateKey : '=',
                    gridHeight   : '=?',
                    hideTools    : '=?',
                    hideUserDefined: '=',
                    buttons      : '=?'
                },       
                templateUrl: 'templates/global/html/grid.template.html',
                controller : ['$scope', function($scope, gridState){
                   $scope.getGridState= function(){
                        gridState
                            .getState($scope.subscriberId, $scope.gridStateKey)
                            .then(function(){                                
                                $scope.ready = true;
                            })
                   };
                    
                }],                
                link : function(scope, element){
                    if(!scope.gridHeight) scope.gridHeight = '600px';                    
                    if(scope.buttons){
                        var gridButtons = element.find('.grid-buttons');
                        scope.customButtonClick = function(index){                            
                            scope.buttons[index].scope[scope.buttons[index].action]();
                        };

                        _.forEach(scope.buttons, function(button, index){
                            var classes = 'btn btn-sm ' + button.buttonClass;
                            var template = '<button class="'+ classes+'"" ng-click="customButtonClick('+index+')">'+button.display+'</button>';
                            var btn = $compile(template)(scope)
                            gridButtons.prepend(btn);                            
                        })
                    }

                    if(scope.gridStateKey && scope.user)
                        scope.getGridState();
                    else
                        scope.ready = true;
                }
            };
        }
    ]);