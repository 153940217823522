angular.module('angus.controllers').controller('adeptExecutionsController', [
    '$rootScope', '$scope', 'adeptInquiryService', 'adeptService', 'constantsService', 'gridService2', 'gridState', 'subscriberService', 
    function($rootScope, $scope, adeptInquiryService, adeptService, constantsService, gridService2, gridState, subscriberService) {

        $scope.settings = {};

        $scope.isSysAdmin = !!$rootScope.user.isSysAdmin;
        
        $scope.showSettings = true;

        $scope.subscriberId = $rootScope.user.subscriberId;


 
        $scope.handleSubscriberChanged = handleSubscriberChanged;
        $scope.isApplyDisabled = isApplyDisabled;
        $scope.runReport = runReport;

        

        var gridParams = {
            gridOptions: {},
            gridState: gridState($scope.subscriberId, 'adeptExecutions'),
            defs: getColDefs(),
            clicks: getColClicks(),
            exportOptions: { fileName: 'ADEPT Executions'}
        };

        gridService2.createGrid(gridParams)
            .then(function(grid) {
                grid.setRows(Promise.resolve());
                
                $scope.grid = grid;                
            });

        function getColDefs() {
            var colDefs = [];

            colDefs.push(gridService2.colDef.createText('dynamicReserveSettingsDescription', 'Group Name', 'dynamicReserveSettingsDescription', {}));

            colDefs.push(gridService2.colDef.createDate('dynamicReserveExecutionDate', 'Run Date', 'dynamicReserveExecutionDate', {sort: 'desc'}));
            colDefs.push(gridService2.colDef.createYear('dynamicReserveExecutionDateYear', 'Run Year', 'dynamicReserveExecutionDateYear', {hide: true}));
            colDefs.push(gridService2.colDef.createMonth('dynamicReserveExecutionDateMonth', 'Run Month', 'dynamicReserveExecutionDateMonth', {hide: true}));

            colDefs.push(gridService2.colDef.createNumber('containerCount', 'Tanks Processed', 'containerCount', {}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('containerCountExportExcluded', 'Tanks Export Excluded', 'containerCountExportExcluded', {}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('containerCountExportSuccess', 'Tanks Exported', 'containerCountExportSuccess', {}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('containerCountExportFailure', 'Tanks Export Failed', 'containerCountExportFailure', {}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('containerCountExportRemoved', 'Tanks Export Date Removed', 'containerCountExportRemoved', {}, {decimalPlaces: 0}));

            colDefs.push(gridService2.colDef.createBoolean('isUnscheduledExecution', 'Unscheduled', 'isUnscheduledExecution'));

            colDefs.push(gridService2.colDef.createTime('approximateExecutionTime', 'Execution Time', 'approximateExecutionTime', 'h:mm A', {hide:true}));
            colDefs.push(gridService2.colDef.createTime('exportCutoffTime', 'Export Cutoff Time', 'exportCutoffTime', 'h:mm A', {hide: true}));
            colDefs.push(gridService2.colDef.createBoolean('isExportTimeExclusion', 'Export Cutoff Missed', 'isExportTimeExclusion', {}, {hide: true}));
            
            return colDefs;
        }

        function getColClicks() {
            return {
                dynamicReserveExecutionDate: {
                    onClick: function(row) {

                        const settings = $scope.settings;
                        const includeExcludeOnly = constantsService.includeExcludeOnly;

                        const params = {
                            subscriberId: settings.selectedSubscriberId,
                            tab: 'adeptGroup',
                            dynamicReserveSettingsKey: row.dynamicReserveSettingsKey,
                            dynamicReserveExecutionKey: row.dynamicReserveExecutionKey,
                            includeUnscheduledDays: row.isUnscheduledExecution ? includeExcludeOnly.include.key : includeExcludeOnly.exclude.key
                        }

                        adeptInquiryService.activateTab(adeptInquiryService.tabs.executionDetails, params);
                    }       
                }   
            }
        }

        function init() {

            /* Default Dates */
            const today = new Date();

            $scope.settings.startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
            $scope.settings.endDate = new Date(today);

            $scope.settings.includeUnscheduledDays = 'exclude';

            /* Get List of Subscribers if SysAdmin */
            if ($scope.isSysAdmin) {
                loadSubscribers();

                /* ToDo: Don't use subscriber service since i'm controlling this */
                $scope.settings.selectedSubscriberId = subscriberService.getSubscriberId();

                if ($scope.settings.selectedSubscriberId === $scope.subscriberId) {
                    $scope.settings.selectedSubscriberId = null;
                }
            }
            else {
                $scope.settings.selectedSubscriberId = $scope.subscriberId;

                loadAdeptGroups();
            }
        }

        function loadSubscribers() {
            $scope.areSubscribersLoading = true;

            return subscriberService.getAdeptSubscribers()
                .then(function(subscribers) {
                    $scope.subscribers = subscribers;
                    $scope.areSubscribersLoading = false;
                });
        }

        function loadAdeptGroups() {
            $scope.areAdeptGroupsLoading = true;

            return adeptService.getAdeptGroupsLegacy($scope.settings.selectedSubscriberId)
                .then(function(adeptGroups) {
                    $scope.adeptGroups = adeptGroups;
                    $scope.areAdeptGroupsLoading = false;

                    if (adeptGroups.length === 1) {
                        $scope.settings.adeptGroupIds = [adeptGroups[0].id];
                    }
            });
        }

        function isApplyDisabled() {

            const settings = $scope.settings;

          
            /* No Subscriber */
            if (!settings.selectedSubscriberId) {
                return true;
            }

            /* No Dates */
            if (!settings.startDate || !settings.endDate) {
                return true;
            }

            return false;
        }


        function handleSubscriberChanged() {
            
            /* Clear ADEPT Groups */
            $scope.settings.adeptGroupIds = [];
            $scope.adeptGroups = [];

            if ($scope.settings.selectedSubscriberId) {
                loadAdeptGroups();
            }            
        }

        function runReport() {

            const settings = $scope.settings;

            const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;

            const subscriberId = settings.selectedSubscriberId;
            const adeptGroupIds = settings.adeptGroupIds;
            const startDate = settings.startDate;
            const endDate = settings.endDate;
            const includeUnscheduledDays = includeExcludeOnlyOptions[settings.includeUnscheduledDays] || includeExcludeOnlyOptions.exclude;

            var rowPromise = adeptService.getExecutions(subscriberId, adeptGroupIds, startDate, endDate, includeUnscheduledDays)
                .then(function (data) {
                    data.forEach(function(row){
                        row.dynamicReserveExecutionDateYear = row.dynamicReserveExecutionDate;
                        row.dynamicReserveExecutionDateMonth = row.dynamicReserveExecutionDate;

                        row.exportCutoffTime = row.exportCutoffDateTime ? moment.utc(row.exportCutoffDateTime).format('hh:mm A') : '';
                        row.approximateExecutionTime = row.approximateExecutionDateTime ? moment.utc(row.approximateExecutionDateTime).format('hh:mm A') : '';
                    });

                    return data;
                });

                var promise = $scope.grid.setRows(rowPromise);

                return promise;

        }

        init();
        
    }
])