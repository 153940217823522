function toastContainerController(toastService) {
    const $ctrl = this;

    $ctrl.toasts = toastService.getToasts();

    $ctrl.closeToast = index => toastService.closeToast(index);
};

angular
    .module('angus.components')
    .component('toastContainer', {
        controller: toastContainerController,
        bindings: {},
        template: `
            <div class="toast-container">
                <div class="toast" 
                     uib-alert 
                     ng-repeat="toast in $ctrl.toasts"
                     ng-class="'alert-' + (toast.type || 'warning')" 
                     close="$ctrl.closeToast($index)">
                    {{toast.message}}
                </div>
            </div>
            `
    });