angular.module('angus.services').factory('datePickerService', ['modalService', 'actionViewTemplates', 'moment', function(modalService, actionViewTemplates, moment) {
	'use strict';

	return {
		openDatePickerModal: function(startDate, endDate, upperBoundDate, minYears) {
			var scope = {
				startDate     : startDate ? moment.utc(startDate).format('YYYY/MM/DD') : null,

				// selects the correct date in date range datepicker pop-up, instead of the following day
				endDate       : moment.utc(endDate).format('YYYY/MM/DD'),
				upperBoundDate: upperBoundDate ? moment.utc(upperBoundDate).format('YYYY/MM/DD') : null,
				minYears      : minYears
			};

			return modalService
				.openTopBarModal(actionViewTemplates.datePicker, scope);
		}
	};
}]);