 
function quantityToBillController(quantityToBillService, moment, _, toastService){ 
    const $ctrl = this;        
    $ctrl.isLoading = false;    
    $ctrl.rowStatus = {
        active: 1,
        delete: 3
    }   
    $ctrl.pageTitle = 'Quantity To Bill';
    $ctrl.months = [1,2,3,4,5,6,7,8,9,10,11,12];
    $ctrl.discountYearHasError = false;
    $ctrl.discountAmountHasError = false;
    $ctrl.defaultBillModel = {};

    $ctrl.$onInit = function() { 
        $ctrl.defaultBillModel = _.cloneDeep($ctrl.resolve.model);
    }

    $ctrl.yearEnteredHandler = function(value) {  
        $ctrl.discountYearHasError = false;
        if (!moment(value, 'YYYY', true).isValid()) {
            $ctrl.discountYearHasError = true; 
        }      
    }

    $ctrl.amountEnteredHandler = function(value) {  
        $ctrl.discountAmountHasError = false;
        amountBilledOverride = parseFloat(value);
        if (isNaN(amountBilledOverride)) { 
            $ctrl.discountAmountHasError = true; 
            $ctrl.resolve.model.amountBilledOverride = $ctrl.defaultBillModel.amountBilledOverride;
        }      
    }

    $ctrl.save = function() { 
        //get total existing quantity
        var totalQuantity = $ctrl.resolve.model.quantityToBills.reduce((sum, item) => sum + (item.rowStatusKey === 1 ? item.quantity : 0), 0);  
        if (($ctrl.resolve.model.quantity + totalQuantity) > $ctrl.resolve.model.riderDetailQuantity) {
            toastService.addToast(toastService.types.danger, 'Rider Detail Quantity must be less than or equal to Quantity to Bill', 5000); 
            return;
        } 
      
        let submitType = 'create';
        const startDate = moment({ year: $ctrl.resolve.model.startYear, month: $ctrl.resolve.model.startMonth - 1}); 
        var quantityToBill = { 
            riderDetailKey: $ctrl.resolve.model.riderDetailKey,
            startDate: startDate,
            quantity: $ctrl.resolve.model.quantity,
            amountBilledOverride: typeof $ctrl.resolve.model.amountBilledOverride === 'string' ? $ctrl.resolve.model.amountBilledOverride.replace('$', '') : $ctrl.resolve.model.amountBilledOverride,
            rowStatusKey: $ctrl.rowStatus.active
        } 
        if ($ctrl.resolve.model.riderDetailQuantityToBillKey) {
            quantityToBill.riderDetailQuantityToBillKey = $ctrl.resolve.model.riderDetailQuantityToBillKey;
        } 

        var promiseResponse = null;
        if (quantityToBill.riderDetailQuantityToBillKey) {
            promiseResponse = quantityToBillService.updateDetailQuantityToBill(quantityToBill.riderDetailQuantityToBillKey, quantityToBill); 
            submitType = 'update';
        }
        else {            
            promiseResponse = quantityToBillService.createDetailQuantityToBill(quantityToBill); 
        }
        promiseResponse
        .then(function(result) { 
            var riderDetailQuantityToBillKey = result;
            $ctrl.close({ 
                $value: {
                    status: 'success',
                    submitType: submitType,
                }
            });
            if (riderDetailQuantityToBillKey > 0) {   
                toastService.addToast(toastService.types.success, 'The bill has been saved successfully', 5000); 
            }
            else {    
                toastService.addToast(toastService.types.danger, 'Failed to Submit, Please check the data!', 5000); 
            }
        })
        .catch(function(error) {      
            toastService.addToast(toastService.types.error, 'Failed to Submit, Please check the data!', 5000); 
            $ctrl.close({
                $value: {
                    status: 'failed'
                }
            });
        });  
    } 

    $ctrl.cancel = () => {
        $ctrl.dismiss({$value: 'cancel'});
    }
}

angular
    .module('angus.components')
    .component('quantityToBill', {
        templateUrl: 'templates/tankMonitor/inventoryManagement/components/quantityToBill/quantityToBillView.html',
        controller: quantityToBillController,
        bindings: {
            resolve: '<', 
            close: '&',
            dismiss: '&'
        }
    });