angular.module('angus.services').factory('quantityToBillService', [
    '$http', function ($http) { 
 
        const getRiderDetailQuantityToBill = function(riderDetailKey) {  
            let url = 'api/internal/rider-detail-quantity-to-bills/by-rider-detail/' + riderDetailKey;  

            return $http.get(url, {apiV2: true})
            .then(function (res) { 
                return res.data;
            });
        };   
         
        const createDetailQuantityToBill = function(data) {
            return $http.post(('api/internal/rider-detail-quantity-to-bills'), data, {apiV2: true})
                .then(function (res) {
                    return res.data;
                });
        };
         
        const updateDetailQuantityToBill = function(quantityToBillKey, data) {
            return $http.put((`api/internal/rider-detail-quantity-to-bills/${quantityToBillKey}`), data, {apiV2: true})
                .then(function (res) {
                    return res.data;
                });
        };

        return {  
            getRiderDetailQuantityToBill,
            createDetailQuantityToBill,
            updateDetailQuantityToBill
         };
     }
 ]);
 
 