angular.module('angus.services').factory('shipmentService', [
    '$http', '$q', function ($http, Q) { 
  
        const getShipmentsForSubscriber = function(subscriberKey, includeDeleted) {   
            let params = { 
                includeDeleted: includeDeleted ? 'true' : 'false'
            }
            return $http.get(`api/internal/shipments/by-subscriber/${subscriberKey}`, {apiV2: true, params: params})
               .then(function (res) {
               return res.data;
           });
        };  

        const getShipmentDetail = function(shipmentKey, includeDeleted) {   
            let params = { 
                includeDeleted: includeDeleted ? 'true' : 'false'
            }
            let url = `api/internal/shipment-details/by-shipment/${shipmentKey}`;   
            return $http.get(url, {apiV2: true, params: params})
            .then(function (res) { 
                return res.data;
            });
        };    
         
        const postShipment = function(data) {
            if (data.shipmentKey) {            
                return $http.put((`api/internal/shipments/${data.shipmentKey}`), data, {apiV2: true})
                .then(function (res) {
                    return res.data;
                });
            }
            else {
                return $http.post(('api/internal/shipments'), data, {apiV2: true})
                .then(function (res) {
                    return res.data;
                });
            } 
        };

        const getMonitorSearch = function(monitorTypeKey, shippingBoxID, deliveryDate, serialNumber, simNumber, simCardCarrierKey) {
            let url =  'api/tankMonitors/shipments/monitorsearch?';          
            if (monitorTypeKey) {
                url += 'monitorTypeKey=' + monitorTypeKey + '&';
            }
            if (shippingBoxID) {
                url += 'shippingBoxID=' + shippingBoxID + '&';
            }
            if (deliveryDate) {
                url += 'deliveryDate=' + deliveryDate + '&';
            }  
            if (serialNumber) {
                url += 'serialNumber=' + serialNumber + '&';
            } 
            if (simNumber) {
                url += 'simNumber=' + simNumber + '&';
            } 
            if (simCardCarrierKey) {
                url += 'simCardCarrierKey=' + simCardCarrierKey;
            } 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        } 

        const postReport = function(data) {
            return $http.post('api/tankMonitors/shipments/report', data, {responseType:'arraybuffer'})
                   .then(function (res) {
                       return res.data;
                   });
        };

        return {  
            getShipmentsForSubscriber,
            getShipmentDetail,
            getMonitorSearch, 
            postShipment,
            postReport
         };
     }
 ]);
 
 