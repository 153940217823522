'use strict';

angular.module('angus.controllers').controller('connectionSetupCtrl', [
  '$scope', '$rootScope', '$http', '$q', '_', 'actionViewTemplates', 'backOfficeSystemService', 'modalService',
  function ($scope, $rootScope, $http, $q, _, actionViewTemplates, backOfficeSystemService, modalService) {

    $scope.defaultSourceSystemType = {
      name: 'Custom',
      id: 0
    };

    $scope.connectionsTab = {
      name: 'Connections'
    };

    $scope.addsPostingCodesTab = {
      name: 'Posting Codes'
    };

    $scope.fdsCategoryCodesTab = {
      name: 'Category Codes'
    };

    $scope.awdMappingsTab = {
      name: 'AWD Mappings'
    };

    $scope.esysDivisionTab = {
      name: 'Integration Settings'
    };

    $scope.cargasIntegrationSettingsTab = {
      name: 'Integration Settings'
    };


    $scope.awdMappingsTemplate = actionViewTemplates.subRegAwdMappings;

    $scope.esysDivisionTemplate = actionViewTemplates.subRegEsysDivision;

    $scope.addsPostingCodesTemplate = actionViewTemplates.subRegPostingCodes;

    $scope.fdsCategoryCodesTemplate = actionViewTemplates.subRegCategoryCodes;

    $scope.cargasIntegrationSettingsTemplate = actionViewTemplates.subRegCargasIntegrationSettings;

    function getSourceSystems() {
      return $http.get(('api/subscribers/{0}/sourceSystems/').format($scope.subscriberId))
        .then(function (sourceSystems) {
          return sourceSystems.data;
        });
    }

    function getAwdSourceSystem() {
      return $http.get(('api/subscribers/{0}/awdMappings/sourceSystems/').format($scope.subscriberId))
        .then(function (srcSysKey) {
          return srcSysKey.data;
        });
    }


    function initializeSourceSystems(backOfficeSystems, sourceSystems) {
      _.forEach(sourceSystems, function (sourceSystem) {
          var mappedBos = _.find(backOfficeSystems, function (backOfficeSystem) {
            return backOfficeSystem.id == sourceSystem.backOfficeSystemId;
          });

          if (mappedBos) {
            sourceSystem.displayName = ('{0} ( {1} )').format(sourceSystem.name, mappedBos.name);
            $rootScope.sourceSystemId = sourceSystem.id;
            sourceSystem.bos = mappedBos;
          } else {
            sourceSystem.displayName = sourceSystem.name;
          }

          $rootScope.sourceSystemId = sourceSystem.id;
          delete sourceSystem.backOfficeSystemId;

          sourceSystem.tabs = [$scope.connectionsTab];
          sourceSystem.activeTab = $scope.connectionsTab;

          $scope.isAdds = sourceSystem.bos && sourceSystem.bos.id == 2;
          $scope.isFds = sourceSystem.bos  && sourceSystem.bos.id == 9;
          $scope.usesCargas = sourceSystem.bos && sourceSystem.bos.id == 4;

          if($scope.isAdds) {
            sourceSystem.tabs.push($scope.addsPostingCodesTab);
            $scope.usingPostingCodeTab = true;
          }

          if($scope.isFds) {
            sourceSystem.tabs.push($scope.fdsCategoryCodesTab, $scope.esysDivisionTab);

            $scope.usingCategoryCodeTab = true;
            $scope.usingesysDivisionTab = true;
          }

          $scope.isEsys = sourceSystem.bos && sourceSystem.bos.id == 3;
          if($scope.isEsys || $scope.isAdds) {
            sourceSystem.tabs.push($scope.esysDivisionTab);

            $scope.usingesysDivisionTab = true;
          }

          if ($scope.awdSourceSystemKeys.length > 0) {
            sourceSystem.tabs.push($scope.awdMappingsTab);

            $scope.usingAwdMappingsTab = true;
          }

          if($scope.usesCargas) {
            sourceSystem.tabs.push($scope.cargasIntegrationSettingsTab);
            $scope.usingCargasIntegrationSettingsTab = true;
          }

      });
      return sourceSystems;
    }

    function init() {
      $q.all([
        backOfficeSystemService.getBackOfficeSystems(),
        getSourceSystems(),
        getAwdSourceSystem(),
       // getEsysSourceSystem()
      ]).then(function (dataSets) {
        $scope.backOfficeSystems = dataSets[0];
        $scope.awdSourceSystemKeys = dataSets[2];
         //$scope.esysSourceSystemKeys = dataSets[3];
        $scope.sourceSystems = initializeSourceSystems($scope.backOfficeSystems, dataSets[1]);
        $q.all(getConnections($scope.sourceSystems))
          .then(function (dataSets) {
            for (var i = 0; i < $scope.sourceSystems.length; i++)
              $scope.sourceSystems[i].connections = dataSets[i];
            $scope.activeSourceSystem = $scope.sourceSystems.length > 0 ? $scope.sourceSystems[0] : null;
            $scope.dataLoaded = true;
          });
      });
    }

    $scope.refresh = function () {
      $scope.dataLoaded = false;

      $q.all(getConnections($scope.sourceSystems))
        .then(function (dataSets) {
          for (var i = 0; i < $scope.sourceSystems.length; i++)
            $scope.sourceSystems[i].connections = dataSets[i];
          //$scope.activeSourceSystem = $scope.sourceSystems.length > 0 ? $scope.sourceSystems[0] : null;
          $scope.dataLoaded = true;
        });
    };

    init();

    function getConnections(sourceSystems) {
      var result = [];
      _.forEach(sourceSystems, function (sourceSystem) {
        result.push(getConnection(sourceSystem.id));
      });
      return result;
    }

    function getConnection(sourceSystemId) {
      return $http.get(('api/subscribers/{0}/sourceSystems/{1}/connections').format($scope.subscriberId, sourceSystemId))
        .then(function (connection) {
          return connection.data;
        });
    }

    $scope.openAddSourceSystemModal = function () {
      modalService
        .openTopBarModal(actionViewTemplates.subRegConnectionAddSourceSystem, {
          backOfficeSystems: $scope.backOfficeSystems
        })
        .then(addSourceSystem);
    };

    function addSourceSystem(results) {
      if (results) {
        var bosId = results[0];
        var name = results[1];


        var postData = {
          name: name,
          subscriberId: $scope.subscriberId,
          backOfficeSystemId: bosId
        };

        $http.post(('api/subscribers/{0}/sourceSystems').format($scope.subscriberId), postData)
          .success(function (sourceSystem) {
            $scope.sourceSystems.push(sourceSystem);
          });
      }
    }

    $scope.openConnectionModal = function (sourceSystem, connection) {
      var scope = {
        connection: (connection) ? _.cloneDeep(connection) : {
          connectionTypeId: 0
        },
        showPassword: false
      };

      modalService
        .openTopBarModal(actionViewTemplates.subRegConnectionAddEditConnection, scope)
        .then(function (connection) {
          if (connection) {
            if (connection.id) {
              $http.put(('api/subscribers/{0}/sourcesystems/{1}/connections/{2}').format($scope.subscriberId, sourceSystem.id, connection.id), connection)
                .success(function () {
                  sourceSystem.connections = _(sourceSystem.connections).map(function (oldConnection) {
                    return (oldConnection.id == connection.id) ? connection : oldConnection;
                  }).value();
                });
            } else {
              $http.post(('api/subscribers/{0}/sourcesystems/{1}/connections').format($scope.subscriberId, sourceSystem.id), connection)
                .success(function (conn) {
                  connection.id = conn.id;
                  _.find($scope.sourceSystems, {
                    id: sourceSystem.id
                  }).connections.push(connection);
                });
            }
          }
        });
    };

    $scope.passwordUpdate = function (connection) {
      if (connection.password === null || connection.password === '') connection.confirmPassword = connection.password;
    };

    $scope.removeConnection = function (sourceSystem, connection) {
      var scope = {
        title: 'Delete ' + connection.code + '?',
        message: 'Are you sure you would like to delete ' + connection.code + ' from the source system ' + sourceSystem.name + '?'
      };

      modalService
        .openTopBarModal(actionViewTemplates.confirmDialog, scope)
        .then(function (result) {
          if (result)
            $http.delete(('api/subscribers/{0}/sourceSystems/{1}/connections/{2}').format($scope.subscriberId, sourceSystem.id, connection.id))
            .success(function () {
              _.remove(sourceSystem.connections, function (c) {
                return c.id == connection.id;
              });
            });
        });
    };

    // $scope.removeSourceSystem = function(sourceSystem, connection) {
    //  var scope = {
    //    title: 'Delete ' + connection.code + '?',
    //    message: 'Are you sure you would like to delete ' + connection.code + ' from the source system ' + sourceSystem.name + '?'
    //  };

    //  modalService
    //    .openTopBarModal(actionViewTemplates.confirmDialog, scope)
    //    .then(function(result) {
    //      if (result)
    //        $http.delete(('api/subscribers/{0}/sourceSystems/{1}/connections/{2}').format($scope.subscriberId, sourceSystem.id, connection.id))
    //        .success(function() {
    //          _(sourceSystem.connections).remove(function(c) {
    //            return c == connection;
    //          });
    //        });
    //    });
    // };
  }
]);
