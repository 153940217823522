'use strict'
angular.module('angus.directives').directive('datePeriodPicker',
    ['constantsService','datePickerService','moment','dateCalculator','$http', 'fiscalYearService',
        function(constantsService, datePickerService, moment, dateCalculator, $http, fiscalYearService) {
            return {
                templateUrl:'templates/global/directives/datePeriodPicker.html',
                link: function(scope, ele, att) {
                    var startDate, endDate;
                    var settings = scope.widget.instance.settings;
                    var subscriberId = scope.subscriberId;

                    settings.periodIndicators = scope.periodIndicators.filter(function(indicator) {
                        return indicator.key !== 'customDate'
                    });

                    if(scope.widget.noContentFlag) {
                        settings.customDate = null;
                    }

                    if(!settings.customDate) {
                        if(scope.dates) {
                            endDate = scope.dates.lastDeliveryDate ||
                                scope.dates.lastInvoiceDate ||
                            scope.dates.lastPostingDate ||
                                scope.dates.lastAcquistionDate ||
                                scope.dates.endDate ||
                                scope.lastDeliveryDate ||
                                scope.lastInvoiceDate ||
                                scope.lastPostingDate ||
                                scope.lastAcquistionDate ||
                                scope.endDate || null;

                            startDate = scope.dates.periodStartDate ||
                                scope.dates.startDate ||
                                scope.periodStartDate ||
                                scope.startDate;

                            settings.startDate = startDate.format();
                            settings.endDate = endDate.format();
                        }
                    } else {
                        startDate = settings.customDate.startDate;
                        endDate = settings.customDate.endDate;
                        settings.startDate = startDate;
                        settings.endDate = endDate
                    }

                    settings.displayDate = moment.utc(startDate).format('MMM DD, YYYY') + ' to ' + moment.utc(endDate).format('MMM DD, YYYY');

                    scope.onSave = function(widget) {
                        return $http({
                            url: ('api/dashboards/{0}/widgets/{1}/settings').format(scope.widget.id, scope.widget.instance.dashboardId),
                            method: 'PUT',
                            data: scope.widget.instance.settings
                        });
                    };

                    scope.openDatePickerForPicker = function() {
                        scope.openDatePicker()
                            .then(function() {
                                if(!settings.customDate) {
                                    settings.customDate = {}
                                }

                                settings.customDate.endDate = scope.dates.lastDeliveryDate ||
                                    scope.dates.lastInvoiceDate ||
                                    scope.dates.lastPostingDate ||
                                    scope.dates.lastAcquistionDate ||
                                    scope.dates.endDate ||
                                    scope.lastDeliveryDate ||
                                    scope.lastInvoiceDate ||
                                    scope.lastPostingDate ||
                                    scope.lastAcquistionDate ||
                                    scope.endDate;

                                settings.customDate.startDate = scope.dates.periodStartDate ||
                                    scope.dates.startDate ||
                                    scope.periodStartDate ||
                                    scope.startDate;
                            })
                            .then(function() {
                                scope.widget.saveFromPicker(scope.widget.instance.settings, scope.onSave);
                            })
                            .catch(function(err) {
                                console.log(err)
                            });
                    };

                    scope.selectPeriod = function() {
                        ele.on('mouseup', function() {
                            scope.$applyAsync(() => {
                                if(settings.periodIndicatorId.value === 'customDateRange') {
                                    scope.openDatePickerForPicker();
                                } else {
                                    settings.customDate = null;
                                    scope.widget.saveFromPicker(scope.widget.instance.settings, scope.onSave)
                                }
                            });
                        });
                    };
                }
            }
        }
    ]);