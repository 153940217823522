angular.module('angus.services').factory('plannedShipmentService', [
    '$http', function ($http) {
        
        const getRiderPlannedShipmentByRider = function(riderKey) {  
            let url =  `api/internal/rider-planned-shipments/by-rider/${riderKey}`; 
            return $http.get(url, {apiV2: true})
            .then(function (res) { 
                return res.data;
            });
        };   
 
        const getRiderPlannedShipmentsByPlannedShipment = function(riderPlannedShipmentKey) {  
            let url =  `api/internal/rider-planned-shipments/${riderPlannedShipmentKey}`; 
            return $http.get(url, {apiV2: true})
            .then(function (res) { 
                return res.data;
            });
        };    
         
        const postRiderPlannedShipment = function(data) {
            return $http.post(('api/internal/rider-planned-shipments'), data, {headers: {'Content-Type': 'application/json'}, apiV2: true})
                   .then(function (res) {
                       return res.data;
                   });
        };

        return {
            getRiderPlannedShipmentByRider,
            getRiderPlannedShipmentsByPlannedShipment,
            postRiderPlannedShipment
         };
     }
 ]);
 
 