'use strict';

angular.module('angus.controllers').controller('subRegCallReasonCtrl', [
	'$scope', '$http', '_', 'Paging',
	function($scope, $http, _, Paging) {

		$scope.pageSize           = 25;
		$scope.paging             = new Paging($scope.pageSize);
		$scope.sortProp           = 'id';
		$scope.sortDir            = 'asc';
		$scope.stagedChangesCount = 0;


		function getDefaultValue(){
			return {
				originalExempt     : [],
				callbackExempt     : [],
				excludedFromExcess : [],
				installation       : [],
				annualTuneup       : []
			};
		}

		var stagedChanges = getDefaultValue();
		var existing      = getDefaultValue();
		var ids = [];

		function init(){
			getCallReasons();
		}

		function syncWithStagedChanges(){
			_.forEach($scope.callReasons, function(reason){
					reason.isOriginalExempt     = _.includes(existing.originalExempt, reason.id);
					reason.isCallbackExempt     = _.includes(existing.callbackExempt, reason.id);
					reason.isExcludedFromExcess = _.includes(existing.excludedFromExcess, reason.id);
					reason.isInstallation       = _.includes(existing.installation, reason.id);
					reason.isAnnualTuneup       = _.includes(existing.annualTuneup, reason.id);
				});
		}

		function getCallReasons(pageNumber){
			var params = {
				includeSettings    : true,
				sortDir            : $scope.sortDir,
				sortProp           : $scope.sortProp,
				originalExempt     : stagedChanges.originalExempt.join(','),
				callbackExempt     : stagedChanges.callbackExempt.join(','),
				excludedFromExcess : stagedChanges.excludedFromExcess.join(','),
				instalation        : stagedChanges.installation.join(','),
				annualTuneup       : stagedChanges.annualTuneup.join(',')
			};

			$scope.callReasonsLoaded = false;

			$scope.paging.getPage(('api/subscribers/{0}/service/callReasons').format($scope.subscriberId),
				pageNumber,
				params)
					.then(function(callReasons) {
							$scope.callReasons = callReasons.items.filter(function(item){
								return item.id != '-1' && item.id != '-2'
							});

							_.forEach(Object.keys(callReasons.extraInfo), function(key){
									existing[key] = callReasons.extraInfo[key];
								});

							ids = callReasons.ids;

							syncWithStagedChanges();
							$scope.callReasonsLoaded = true;
						});
		}

		$scope.getPage = function(pageNumber) {
			getCallReasons(pageNumber);
		};

		$scope.sort = function(propName) {
			if (propName != $scope.sortProp)
				$scope.sortDir = 'asc';
			else
				$scope.sortDir = $scope.sortDir == 'asc' ?
									'desc' : 'asc';

			$scope.sortProp = propName;
			getCallReasons($scope.paging.currentPage);
		};

		function updateArray(array, value){
			if(!_.includes(array, value))
				array.push(value);
			else
				array.splice(array.indexOf(value), 1);
		}

		$scope.updateStagedChanges = function(id, propName){
			updateArray(stagedChanges[propName], id);

			$scope.stagedChangesCount = 0;

			// update staged values
			_.forEach(Object.keys(stagedChanges), function(key){
					$scope.stagedChangesCount += stagedChanges[key].length;
				});
		};


		$scope.updateCallReasons = function() {
			$scope.updating = true;

			var updates = {};

			// union changes with existing
			_.forEach(Object.keys(stagedChanges), function(key){
					var changesFromStages =  _.filter(stagedChanges[key], function(item){
							return !_.includes(existing[key], item);
						});
					var existingUnchanged = _.filter(existing[key], function(item){
							return !_.includes(stagedChanges[key], item);
						});

					updates[key] = _.union(changesFromStages, existingUnchanged);
				});

			updates.ids = ids;

			$http.post(('api/subscribers/{0}/service/callReasons').format($scope.subscriberId), updates)
				.then(function() {
						$scope.updating = false;
						$scope.stagedChangesCount = 0;

						stagedChanges = getDefaultValue();
						getCallReasons();
					});
		};

		var headingCenter = 'text-center clickable';

		$scope.headings = [{
					name: 'Call Reason',
					isHidable: false,
					propClass: headingCenter,
					propName: 'code',
					isSortable: true
				}, {
					name: 'Description',
					isHidable: false,
					propClass: headingCenter,
					propName: 'desc',
					isSortable: true
				}, {
					name: 'Original Call Reason Exempt',
					isHidable: false,
					propClass: headingCenter,
					propName: 'originalExempt',
					isSortable: true
				}, {
					name: 'Callback Reason Exempt',
					isHidable: false,
					propClass: headingCenter,
					propName: 'callbackExempt',
					isSortable: true
				}, {
					name: 'Exclude from Excess Calls',
					isHidable: false,
					propClass: headingCenter,
					propName: 'excludedFromExcess',
					isSortable: true
				}, {
					name: 'Installation',
					isHidable: false,
					propClass: headingCenter,
					propName: 'installation',
					isSortable: true
				}, {
					name: 'Annual Tune-up',
					isHidable: false,
					propClass: headingCenter,
					propName: 'annualTuneup',
					isSortable: true
				},
			];

		init();
	}
]);
