angular.module('angus.services').service('serviceCallsService', [
    '$http',
    function($http) {

        function getServiceCallWorkPerformed(subscriberKey) {
            return $http.get(`api/subscriber/${subscriberKey}/service-call-work-performed`, {apiV2: true})
                .then(response => {
                    /* Return data sorted by name */
                    return response.data.sort((a, b) => {
                        const nameA = a.serviceCallWorkPerformedName.toUpperCase();
                        const nameB = b.serviceCallWorkPerformedName.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        } 
                    
                        if (nameA > nameB) {
                            return 1;
                        }

                        return 0;
                    });
                });
                    
        }

        function getServiceCallFunctions(subscriberKey) {
            return $http.get(`api/subscriber/${subscriberKey}/service-call-functions`, {apiV2: true})
                .then(response => {
                    return response.data.sort((a, b) => {
                        const nameA = a.serviceCallFunctionName.toUpperCase();
                        const nameB = b.serviceCallFunctionName.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        } 
                    
                        if (nameA > nameB) {
                            return 1;
                        }

                        return 0;
                    });
                });
        }


        return {
            getServiceCallWorkPerformed: getServiceCallWorkPerformed,
            getServiceCallFunctions: getServiceCallFunctions
        };
    }
]);