angular.module('angus.controllers').controller('alertResultsController', [
    '$q', '$rootScope', '$scope', '$uibModal', 'alertsFrameworkService', 'gridService2', 'gridState', 'subscriberService', 'windowsService',
    function($q, $rootScope, $scope, $uibModal, alertsFrameworkService, gridService2, gridState, subscriberService, windowsService) {

        /*** PARAMS ***
         * [$scope.model]
         * subscriberId
         * alertKey
         */

        const alertDetailsModes = alertsFrameworkService.alertDetailsModes;
        
        $scope.widgetCode = 'alertResults';
        $scope.subscriberId = $rootScope.user.subscriberId;
        $scope.isSysAdmin = !!$rootScope.user.isSysAdmin;

        $scope.areSubscribersLoading = false;
        $scope.areAlertsLoading = false;
        $scope.areAlertTypeAlertColumnsLoading = false;
        $scope.isReportLoading = false;

        $scope.subscribers = [];
        $scope.alerts = [];

        $scope.alertTypeKey = null;

        $scope.isApplyDisabled = () => {
            const settings = $scope.settings;

            /* No Subscriber */
            if (!settings.subscriberKey) {
                return true;
            }

            /* No Alert */
            if (!settings.alertKey) {
                return true;
            }

             /* Data Loading */
             if ($scope.areAlertTypeAlertColumnsLoading || $scope.isReportLoading) {
                return true;
            }

            return false;
        }

        $scope.subscriberChanged = () => {
            $scope.settings.alertKey = null;
            return getAlerts();
        }

        $scope.alertChanged = () => {
            /* Update screen based on Alert Type */
            setAlertType()
                .then(() => {
                    runReport();
                });
        }

        /* Saved Settings Drop Down changed */
        $scope.savedSettingsChanged = (filter) => {
            const gridState = filter && filter.gridState;
            getAlerts()
                .then(() => {    
                    setAlertType(gridState)
                        .then(() => {
                            runReport();
                        });
            });
        }

        $scope.viewAlert = () => {
            $uibModal.open({
                component: 'alertDetails',
                backdrop: 'static',
                keyboard: false,
                size: "lg",
                resolve: {
                    alertKey: () => $scope.settings.alertKey,
                    alertDetailsMode: () => alertDetailsModes.readOnly,
                    subscriberKey: () => $scope.settings.subscriberKey
                }
            });
        }

        /* Handle changing settings when the window is already open */
        windowsService.setCallingScopeCallBack($scope.windowId, (newCallingScope) => {
            const newModel = newCallingScope.model;

            var getAlertsPromise = $q.resolve();

            /* Subscriber Changed */
            if (newModel.subscriberKey && newModel.subscriberKey !== $scope.settings.subscriberKey)
            {
                $scope.settings.alertKey = null;
                $scope.settings.subscriberKey = newModel.subscriberKey;
                getAlertsPromise = getAlerts();
            }

            /* AlertKey Changed */
            if (newModel.alertKey && newModel.alertKey !== $scope.settings.alertKey)
            {
                $scope.settings.alertKey = newModel.alertKey;
                
                getAlertsPromise
                    .then(() => {       
                        /* Update screen based on Alert Type. Grid is also initialized. */
                        setAlertType()
                            .then(() => {
                                runReport();
                            });
                        });
            }
        })
               


        function getAllColumns() {
            var availableColumns = {
                /* Dates*/
                CustomerAcquisitionDate: gridService2.colDef.createDateNG('customerAcquisitionDate', 'Customer Acquisition Date', 'customerAcquisitionDate', {/*sort: 'desc'*/}, {}),
                CustomerTerminationDate: gridService2.colDef.createDateNG('customerTerminationDate', 'Customer Termination Date', 'customerTerminationDate', {}, {}),
                DeliveryDate: gridService2.colDef.createDateNG('deliveryDate', 'Delivery Date', 'deliveryDate', {hide: true}, {}),
                EventDate: gridService2.colDef.createDateNG('eventDate', 'Event Date', 'eventDate', {}, {}),
                LastLevelDate: gridService2.colDef.createDateNG('lastLevelDate', 'Last Level Date', 'lastLevelDate', {}, {}),
                LevelStagnantSinceDate: gridService2.colDef.createDateNG('levelStagnantSinceDate', 'Level Stagnant Since Date', 'levelStagnantSinceDate', {}, {}),
                PreviousLevelReadingDate: gridService2.colDef.createDateNG('previousLevelReadingDate', 'Previous Level Reading Date', 'previousLevelReadingDate', {}, {}),
                LastReadingDate: gridService2.colDef.createDateNG('lastReadingDate', 'Last Reading Date', 'lastReadingDate', {}, {}),
                
                /* Time */
                DeliveryDateTime: gridService2.colDef.createTime('deliveryDateTime', 'Delivery Time', 'deliveryDateTime', 'h:mm A', {hide:true}),

                /* Integer */
                ConsecutiveStagnantLevels: gridService2.colDef.createNumber('consecutiveStagnantLevels', 'Consecutive Stagnant Levels', 'consecutiveStagnantLevels', {hide: true}, { decimalPlaces: 0 }),
                ContainerCount: gridService2.colDef.createNumber('containerCount', 'Container Count', 'containerCount', {hide: true}, { decimalPlaces: 0 }),
                ContainerLevelCount: gridService2.colDef.createNumber('containerLevelCount', 'Container Level Count', 'containerLevelCount', {hide: true}, { decimalPlaces: 0 }),
                DaysSinceLastReading: gridService2.colDef.createNumber('daysSinceLastReading', 'Days Since Last Reading', 'daysSinceLastReading', {hide: true}, { decimalPlaces: 0 }),
                MappedContainerCount: gridService2.colDef.createNumber('mappedContainerCount', 'Mapped Container Count', 'mappedContainerCount', {hide: true}, { decimalPlaces: 0 }),
                MonitorReadingCount: gridService2.colDef.createNumber('monitorReadingCount', 'Monitor Reading Count', 'monitorReadingCount', {hide: true}, { decimalPlaces: 0 }),
                MonitorReadingCountAverage: gridService2.colDef.createNumber('monitorReadingCountAverage', 'Monitor Reading Count Average', 'monitorReadingCountAverage', {hide: true}, { decimalPlaces: 0 }),
                MonitorTypeCalculation: gridService2.colDef.createNumber('monitorTypeCalculation', 'Monitor Type Calculation', 'monitorTypeCalculation', {hide: true}, { decimalPlaces: 0 }),
                TankCount: gridService2.colDef.createNumber('tankCount', 'Tank Count', 'tankCount', {hide: true}, { decimalPlaces: 0 }),
                TankSize: gridService2.colDef.createNumber('tankSize', 'Tank Size', 'tankSize', {hide: true}, { decimalPlaces: 0 }),

                /* Decimal */
                ContainerLevelCountAverage: gridService2.colDef.createNumber('containerLevelCountAverage', 'Container Level Count Average', 'containerLevelCountAverage', {hide: true}, { decimalPlaces: 1 }),
                DeliveryBatchHours: gridService2.colDef.createNumber('deliveryBatchHours', 'Delivery Batch Hours', 'deliveryBatchHours', {hide: true}, { decimalPlaces: 1 }),
                DeliveryBatchMiles: gridService2.colDef.createNumber('deliveryBatchMiles', 'Delivery Batch Miles', 'deliveryBatchMiles', {hide: true}, { decimalPlaces: 1 }),
                DeliveryBatchUnits: gridService2.colDef.createNumber('deliveryBatchUnits', 'Delivery Batch Units', 'deliveryBatchUnits', {hide: true}, { decimalPlaces: 1 }),
                DeliveryCount: gridService2.colDef.createNumber('deliveryCount', 'Delivery Count', 'deliveryCount', {hide: true}, { decimalPlaces: 1 }),
                InternalPCBTemperatureAverage: gridService2.colDef.createNumber('internalPCBTemperatureAverage', 'Internal PCB Temperature Average', 'internalPCBTemperatureAverage', {hide: true}, { decimalPlaces: 1 }),
                LastLevel: gridService2.colDef.createNumber('lastLevel', 'Last Level', 'lastLevel', {hide: true}, { decimalPlaces: 1 }),
                LastReadingLevelVolume: gridService2.colDef.createNumber('lastReadingLevelVolume', 'Last Reading Level Volume', 'lastReadingLevelVolume', {hide: true}, { decimalPlaces: 1 }),
                LevelDrop: gridService2.colDef.createNumber('levelDrop', 'Level Drop', 'levelDrop', {hide: true}, { decimalPlaces: 1 }),
                LevelRiseGallons: gridService2.colDef.createNumber('levelRiseGallons', 'Level Rise Gallons', 'levelRiseGallons', {hide: true}, { decimalPlaces: 1 }),
                LostMargin: gridService2.colDef.createNumber('lostMargin', 'Lost Margin', 'lostMargin', {hide: true}, { decimalPlaces: 2 }),
                LostUnits: gridService2.colDef.createNumber('lostUnits', 'Lost Units', 'lostUnits', {hide: true}, { decimalPlaces: 1 }),             
                Units: gridService2.colDef.createNumber('units', 'Units', 'units', {hide: true}, { decimalPlaces: 1 }),

                /* Percent */
                ContainerLevelCountChangePercentage: gridService2.colDef.createPercentage('ContainerLevelCountChangePercentage', 'Container Level Count Change Percentage', 'ContainerLevelCountChangePercentage', {hide: true}, { decimalPlaces: 1 }),
                LevelPercentFull: gridService2.colDef.createPercentage('LevelPercentFull', 'Level Percent Full', 'LevelPercentFull', {hide: true}, { decimalPlaces: 1 }),
                LevelRisePercent: gridService2.colDef.createPercentage('LevelRisePercent', 'Level Rise Percent', 'LevelRisePercent', {hide: true}, { decimalPlaces: 1 }),
                MappedContainerCountChangePercentage: gridService2.colDef.createPercentage('mappedContainerCountChangePercentage', 'Mapped Container Count Change Percentage', 'mappedContainerCountChangePercentage', {hide: true}, { decimalPlaces: 1 }),
                MonitorReadingCountChangePercentage: gridService2.colDef.createPercentage('monitorReadingCountChangePercentage', 'Monitor Reading Count Change Percentage', 'monitorReadingCountChangePercentage', {hide: true}, { decimalPlaces: 1 }),
                UnitsPercentageTankSize: gridService2.colDef.createPercentage('UnitsPercentageTankSize', 'UnitsPercentageTankSize', 'UnitsPercentageTankSize', {hide: true}, { decimalPlaces: 1 }),
                UnitsPercentageUsable: gridService2.colDef.createPercentage('UnitsPercentageUsable', 'UnitsPercentageUsable', 'UnitsPercentageUsable', {hide: true}, { decimalPlaces: 1 }),
                
                /* Bool */
                IsExcessiveLevelDrop: gridService2.colDef.createBoolean('isExcessiveLevelDrop', 'Excessive Level Drop', 'isExcessiveLevelDrop', {}, {hide: true}),
                IsExistingDeliveryTicket: gridService2.colDef.createBoolean('isExistingDeliveryTicket', 'Existing Delivery Ticket', 'isExistingDeliveryTicket', {}, {hide: true}),
                IsManualConnection: gridService2.colDef.createBoolean('isManualConnection', 'Manual Connection', 'isManualConnection', {}, {hide: true}),
                IsNewInstall: gridService2.colDef.createBoolean('isNewInstall', 'New Install', 'isNewInstall', {}, {hide: true}),
                IsServiceOnly: gridService2.colDef.createBoolean('isServiceOnly', 'Service Only', 'isServiceOnly', {}, {hide: true}),
                IsWeakSonar: gridService2.colDef.createBoolean('isWeakSonar', 'Weak Sonar', 'isWeakSonar', {}, {hide: true}),
                IsWillCall: gridService2.colDef.createBoolean('isWillCall', 'Will Call', 'isWillCall', {}, {hide: true}),

                /* String */
                ContainerContentTypeName: gridService2.colDef.createText('containerContentTypeName', 'Container Content Type', 'containerContentTypeName', {hide: true}, {}),
                ContainerID: gridService2.colDef.createText('containerId', 'Container ID', 'containerId', {hide: true}, {}),
                ContainerName: gridService2.colDef.createText('containerName', 'Container Name', 'containerName', {hide: true}, {}),
                ContainerUsageTypeName: gridService2.colDef.createText('containerUsageTypeName', 'Container Usage Type', 'containerUsageTypeName', {hide: true}, {}),
                CustomerAddress: gridService2.colDef.createText('customerAddress', 'Customer Address', 'customerAddress', {hide: true}, {}),
                CustomerBillToAddressLine1: gridService2.colDef.createText('customerBillToAddressLine1', 'Bill To Address Line 1', 'customerBillToAddressLine1', {hide: true}, {}),
                CustomerBillToAddressLine2: gridService2.colDef.createText('customerBillToAddressLine2', 'Bill To Address Line 2', 'customerBillToAddressLine2', {hide: true}, {}),
                CustomerBillToCity: gridService2.colDef.createText('customerBillToCity', 'Bill To City', 'customerBillToCity', {hide: true}, {}),
                CustomerBillToEmailAddress: gridService2.colDef.createText('customerBillToEmailAddress', 'Bill To Email Address', 'customerBillToEmailAddress', {hide: true}, {}),
                CustomerBillToPhoneNumber1: gridService2.colDef.createText('customerBillToPhoneNumber1', 'Bill To Phone Number 1', 'customerBillToPhoneNumber1', {hide: true}, {}),
                CustomerBillToPostalCode: gridService2.colDef.createText('customerBillToPostalCode', 'Bill To Postal Code', 'customerBillToPostalCode', {hide: true}, {}),
                CustomerBillToState: gridService2.colDef.createText('customerBillToState', 'Bill To State', 'customerBillToState', {hide: true}, {}),
                CustomerDivisionName: gridService2.colDef.createText('customerDivisionName', 'Division Name', 'customerDivisionName', {hide: true}, {}),
                CustomerFirstName: gridService2.colDef.createText('customerFirstName', 'First Name', 'customerFirstName', {hide: true}, {}),
                CustomerID: gridService2.colDef.createText('customerId', 'Customer ID', 'customerId', {hide: true}, {}),
                CustomerLastName: gridService2.colDef.createText('customerLastName', 'Last Name', 'customerLastName', {hide: true}, {}),
                CustomerName: gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', {hide: true}, {}),
                CustomerPrimarySalesRepCode: gridService2.colDef.createText('customerPrimarySalesRepCode', 'Primary Sales Rep Code', 'customerPrimarySalesRepCode', {hide: true}, {}),
                CustomerPrimarySalesRepName: gridService2.colDef.createText('customerPrimarySalesRepName', 'Primary Sales Rep', 'customerPrimarySalesRepName', {hide: true}, {}),
                CustomerSourceAcquisitionSourceName: gridService2.colDef.createText('customerSourceAcquisitionSourceName', 'Acquisition Source', 'customerSourceAcquisitionSourceName', {hide: true}, {}),
                CustomerSourceTerminationReasonName: gridService2.colDef.createText('customerSourceTerminationReasonName', 'Termination Reason', 'customerSourceTerminationReasonName', {hide: true}, {}),
                DeliveryBatchID:  gridService2.colDef.createText('deliveryBatchId', 'Delivery Batch ID', 'deliveryBatchId', {hide: true}, {}),
                DeliveryCenterID: gridService2.colDef.createText('deliveryCenterId', 'Delivery Center ID', 'deliveryCenterId', {hide: true}, {}),
                DeliveryCenterName: gridService2.colDef.createText('deliveryCenterName', 'Delivery Center', 'deliveryCenterName', {hide: true}, {}),
                DeliveryID: gridService2.colDef.createText('deliveryId', 'Delivery ID', 'deliveryId', {hide: true}, {}),
                DriverID: gridService2.colDef.createText('driverId', 'Driver ID', 'driverId', {hide: true}, {}),
                DriverName: gridService2.colDef.createText('driverName', 'Driver', 'driverName', {hide: true}, {}),
                ExceptionTypeName: gridService2.colDef.createText('exceptionTypeName', 'Exception Type', 'exceptionTypeName', {hide: true}, {}),
                GainLossTypeName: gridService2.colDef.createText('gainLossTypeName', 'Gain Loss Type', 'gainLossTypeName', {hide: true}, {}),
                HierarchyNodeName: gridService2.colDef.createText('hierarchyNodeName', 'Hierarchy Node', 'hierarchyNodeName', {hide: true}, {}),
                MonitorSerialNumber: gridService2.colDef.createText('monitorSerialNumber', 'Monitor Serial Number', 'monitorSerialNumber', {hide: true}, {}),
                SourceDeliveryTypeName: gridService2.colDef.createText('sourceDeliveryTypeName', 'Delivery Type', 'sourceDeliveryTypeName', {hide: true}, {}),
                SourceProductDesc: gridService2.colDef.createText('sourceProductDesc', 'Product', 'sourceProductDesc', {hide: true}, {}),
                TankShape: gridService2.colDef.createText('tankShape', 'Tank Shape', 'tankShape', {hide: true}, {}),
                TruckCode: gridService2.colDef.createText('truckCode', 'Truck Code', 'truckCode', {hide: true}, {}),
                TruckDesc: gridService2.colDef.createText('truckDesc', 'Truck Description', 'truckDesc', {hide: true}, {}),
                TruckName: gridService2.colDef.createText('truckName', 'Truck Name', 'truckName', {hide: true}, {}),
            };

            return availableColumns;
        }


        function init() {
            /* Default Settings */
            $scope.settings = {};

            /* Handle Subscriber */
            if ($scope.isSysAdmin) {
                /* Load Subscribers Drop Down */
                getSubscribers();

                /* Set SubscriberKey from parameter. Can be null */
                $scope.settings.subscriberKey = $scope.model.subscriberKey;

            } 
            else {
                /* Set SubscriberKey to the User's SubscriberKey */
                $scope.settings.subscriberKey = $rootScope.user.subscriberAbosKey
            }

            getAlerts()
                .then(() => {

                    /* If an Alert Key was passed in then use it and run the report */
                    if ($scope.model.alertKey) {
                        $scope.settings.alertKey = $scope.model.alertKey;
                        
                        /* Update screen based on Alert Type. Grid is also initialized. */
                        setAlertType()
                            .then(() => {
                                runReport();
                            });
                    }
                });
        }

        function getSubscribers() {
            $scope.areSubscribersLoading = true;

            return subscriberService.getAllLive()
                .then(subscribers => {
                    $scope.subscribers = subscribers;
                    $scope.areSubscribersLoading = false;
                });
        }

        function getAlerts() {
            const subscriberKey = $scope.settings.subscriberKey;

            if (!subscriberKey) {
                return $q.resolve();
            }

            $scope.areAlertsLoading = true;
            
            return alertsFrameworkService.getAlerts(subscriberKey)
                .then(function(alerts) {
                    $scope.alerts = alerts;
                    $scope.areAlertsLoading = false;
                });
        }

        function setAlertType(gridState) {
            const alert = $scope.alerts && $scope.alerts.find(alert => alert.alertKey === $scope.settings.alertKey);
            if (!alert) {
                return $q.resolve();
            }

            const alertTypeKey = alert.alertTypeKey;

            if ($scope.alertTypeKey === alertTypeKey)
            {
                /* Same Alert Type. Do nothing. */
                return $q.resolve();
            }

            $scope.alertTypeKey = alertTypeKey;

            /* Get Columns for this Alert Type */
            return getAlertTypeAlertColumns()
                .then(() => {
                    /* Update Grid based on Alert Type */
                    const colDefs = getColDefs();

                    /* Grid */
                    if (colDefs) {
                        return setupGrid(colDefs)
                            .then(() => {
                                if (gridState) {
                                    $scope.grid.setState(gridState);
                                }
                            })
                    }
                });
        }

        function getColDefs() {
            const alertTypeAlertColumns = $scope.alertTypeAlertColumns;

            const allColumns = getAllColumns();

            if (!alertTypeAlertColumns) {
                return;
            }

            alertTypeAlertColumns.sort((a, b) => {
                if (a.defaultColumnSortOrder == null) {
                    return 1;
                }

                if (b.defaultColumnSortOrder == null) {
                    return -1;
                }
                
                return a.defaultColumnSortOrder - b.defaultColumnSortOrder;
            });
            
            var colDefs = [];
            alertTypeAlertColumns.forEach(alertTypeAlertColumn => {
                const columnName = alertTypeAlertColumn.alertColumnName;

                const column = allColumns[columnName]
                const columnCopy = Object.assign({}, column);

                /* Set visible by default if the column has a Default Sort Order */
                if (alertTypeAlertColumn.defaultColumnSortOrder == null) {
                    columnCopy.hide = true;
                } else {
                    columnCopy.hide = false;
                }

                /* If the Column has an Alias then use it. */
                if (alertTypeAlertColumn.defaultColumnNameAlias) {
                    columnCopy.headerName = alertTypeAlertColumn.defaultColumnNameAlias;
                }

                colDefs.push(columnCopy);
            });

            return colDefs;
        }

        function setupGrid(colDefs) {

            /* Grid already exists. Update Column Definitions */  
            if ($scope.grid) {              
                $scope.grid.setColDefs(colDefs, false); /* Refresh is false here, but is set to true when the report is run. That way we don't see the new column defs until the new data has been resolved */
                return $q.resolve();
            }

            /* Setup new Grid */            
            const gridParams = {
                gridOptions: {},
                gridState: gridState($scope.subscriberId, 'alertResults'),
                defs: colDefs,
                // clicks: getColClicks(),
                exportOptions: {fileName: 'Alert Results'} /* ToDo, can this be based on the selected alert? */
            };

            return gridService2.createGrid(gridParams)
                .then(function (grid) {
                    $scope.grid = grid;
                });
        }

        function getAlertTypeAlertColumns() {
            $scope.areAlertTypeAlertColumnsLoading = false;
            
            return alertsFrameworkService.getAlertTypeAlertColumnsByAlertTypeKey($scope.settings.subscriberKey, $scope.alertTypeKey)
                .then(alertTypeAlertColumns => {
                    $scope.alertTypeAlertColumns = alertTypeAlertColumns;
                    $scope.areAlertTypeAlertColumnsLoading = false;
                });
        }

        function runReport() {
            $scope.isReportLoading = true;

            const settings = $scope.settings;

            var rowPromise = alertsFrameworkService.getAlertResults(settings.subscriberKey, settings.alertKey)
                .then((data) => {
                    $scope.isReportLoading = false;
                    return data;
                });

            var promise = $scope.grid.setRows(rowPromise, true);

            return promise;
        }

        init();
    }
]);
                    
