angular.module('angus.controllers').controller('volumeToBudgetController', [
    '$scope', '$rootScope', '$q', '$http', 'hierarchyService', '_', 'moment', 'fiscalYearService', 'restrictedAccessService', 'fluentRest', 'dateCalculator', 'budgetsService',
    function($scope, $rootScope, $q, $http, hierarchyService, _, moment, fiscalYearService, restrictedAccessService, fluentRest, dateCalculator, budgetsService) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
        var divisions = [];
        var budgets = new budgetsService($rootScope.user.subscriberId);
        $scope.budgetDataAvailable = budgetDataAvailable;


        var fiscalYearOfToday = null,
            months;
        function getBudgets() {
            if($scope.widget.instance.settings.hierarchyNodeId.length < 1){
                $scope.budgetTotalsData = {};
                return;
            }
            var productCategoryName = $scope.widget.instance.settings && $scope.widget.instance.settings.productCategoryIds && $scope.widget.instance.settings.productCategoryIds.value.length
                ? $scope.widget.instance.settings.productCategoryIds.value.map(id => $scope.productCategories.find(obj => obj.id === id).name)
                : $scope.productCategories.map(obj => obj.name);
            var params = {
                h  : $scope.widget.instance.settings.hierarchyNodeId,
                wdo: $scope.widget.instance.settings.workingDaySubtraction ? $scope.widget.instance.settings.workingDaySubtraction.value : 0,
                pc : $scope.widget.instance.settings.productCategoryIds ? $scope.widget.instance.settings.productCategoryIds.value : null,
                tc: $scope.widget.instance.settings.tradeClassCategoryIds ? $scope.widget.instance.settings.tradeClassCategoryIds.value : null,
                ppc: $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : null,
                fy : $scope.fiscalYear
            };
            if($scope.widget.instance.settings.periodIndicatorKey && $scope.widget.instance.settings.periodIndicatorKey.value != 'currentYear'){
                params.m  = $scope.month;
            }

             return $scope.widget.promises.monitor($http({
                method: 'GET',
                url: (('api/subscribers/{0}/budgets/variance').format(subscriberId)),
                params
            })
            .then(function(response) {

                $scope.budgetTotalsData = {};
                var budgetsContainer = [];

                _.forEach(productCategoryName, function(category) {
                    _.forEach(response.data.budgets, function(budget) {
                        if(category === budget.productCategory) {
                            budgetsContainer.push({
                                productCategory: category,
                                budgetedUnits: budget.budgeted.units,
                                periodBudgetUnits: budget.periodBudget.units,
                                currentUnits: budget.current.units,
                                varianceUnits: budget.variance.units,
                                percentVariance: budget.budgeted.units ? ( (budget.variance.units / budget.budgeted.units) * 100) : 0,
                                percentOfBudget: budget.budgeted.units ? ( (budget.current.units / budget.budgeted.units) * 100) : 0,
                                percentOfPeriodBudget: budget.budgeted.units ? ( (budget.current.units / budget.periodBudget.units) * 100) : 0
                            });
                        }
                    });
                });

                $scope.budgetTotalsData.budgets = _.reduce(budgetsContainer, function(result, budget) {
                    var existing = _.find(result, function(item) { return item.productCategory === budget.productCategory });

                    if(existing) {
                        existing.budgetedUnits += budget.budgetedUnits;
                        existing.periodBudgetUnits += budget.periodBudgetUnits;
                        existing.currentUnits += budget.currentUnits;
                        existing.varianceUnits += budget.varianceUnits;
                        existing.percentVariance = existing.budgetedUnits ? ( (existing.varianceUnits / existing.budgetedUnits) * 100) : 0;
                        existing.percentOfBudget = existing.budgetedUnits ? ( (existing.currentUnits / existing.budgetedUnits) * 100) : 0;
                        existing.percentOfPeriodBudget = existing.periodBudgetUnits ? ( (existing.currentUnits / existing.periodBudgetUnits) * 100) : 0;
                    } else
                        result.push(budget);

                    return result;
                }, []);

                $scope.budgetTotalsData.dataThroughDate = response.data.dataThroughDate;
                $scope.budgetTotalsData.endDate = response.data.endDate;
                $scope.budgetTotalsData.lastTrxDate = response.data.lastTrxDate;
                $scope.budgetTotalsData.startDate = response.data.startDate;
                $scope.budgetTotalsData.workingDays = {
                    elapsed: response.data.workingDays.elapsed,
                    total: response.data.workingDays.total
                };

                $scope.widget.setDefaultActionModel({
                    month: $scope.month,
                    year: $scope.fiscalYear
                });
            }).catch(function(error){
                    $scope.budgetTotalsData = {};
                })
            )


        }
        function budgetDataAvailable(budget){
            return Object.keys(budget ? budget : {} ).length > 0;
        }
        function addYearsToMonths() {
            var year = $scope.fiscalYear;

            if(moment().month(months[0]).month() > 0) year--;

            $scope.months = _.map(months, function(month, index) {
                var monthNumber = moment().month(month).month();

                if(monthNumber === 0 && index !== 0) year++;

                return {
                    display: month,
                    value: monthNumber
                };
            });
        }

        $scope.yearChanged = function() {
            $scope.widget.noContentFlag = false;

            fiscalYearService
                .getFiscalYearMonthsOrdered()
                .then(function(months) {
                    var startMonth = moment().month(months[0]).month();
                    var currentMonth = moment().month();

                    $scope.months = _(months)
                        .map(function(m) {
                            return {
                                value: moment().month(m).month(),
                                display: m
                            };
                        })
                        .filter(function(m) {
                            return (fiscalYearOfToday > $scope.fiscalYear) || (m.value <= currentMonth) || (m.value >= startMonth);
                        })
                        .value();

                    var lastMonth = $scope.months[$scope.months.length - 1].value;

                    if( !$scope.month || ((fiscalYearOfToday === $scope.fiscalYear) && ($scope.month > lastMonth) && ($scope.month < startMonth)) )
                        $scope.month = $scope.month || currentMonth;

                    $scope.reloadWidget();
                });

        };

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            var promise = $q
                .all([
                    fiscalYearService
                        .getFiscalYearMonthsOrdered()
                        .then(function(m) {
                            $scope.month = moment().month();
                            $scope.fiscalYear = fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());
                            months = m;
                            addYearsToMonths();
                        }),
                    restrictedAccessService
                        .getAccess()
                        .then(function(access) {
                            $scope.restrictedAccess = access.restrictedAccess;
                        }),
                    budgets
                        .getProducts()
                        .then(function(productCategories){
                            $scope.productCategories = productCategories;
                        })
                ])
                .then(function() {
                    $scope.yearChanged();
                    return getBudgets();
                })
                .then(function() {
                    return fluentRest
                        .api()
                        .subscribers(subscriberId)
                        .budgets()
                        .yearsForVolume()
                        .get()
                        .then(function(years) {
                            $scope.years = years;
                        });
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.reloadWidget = function() {
            $scope.widget.promises.monitor(getBudgets()) };
        $scope.fiscalYearChanged = function() {
            $scope.reloadWidget() };
        $scope.monthChanged = function() {
            $scope.reloadWidget() };

        $scope.saveDashboardHierarchyNodeDropdownSettings = function(save) {
            if(save) {
                $scope.reloadWidget();
                $scope.widget.saveSettings();
            } else {
                $scope.reloadWidget();
            }
        };

        function checkIfDefaultDashboard() {
            return $http({
                url: 'api/dashboards',
                method: 'GET'
            })
            .then(function(dashboards) {
                _.forEach(dashboards.data, function(dashboard) {
                    if(dashboard.isDefaultDashboard) {
                        $scope.isDefaultDashboard = true;
                    }
                })
            });
        }
        checkIfDefaultDashboard();

        $scope.widget.setDefaultInitialization(loadWidget);
        $scope.$on('widgetSettingsChanged', function() {
            $scope.reloadWidget();
        });
    }
]);
